import MusicXML from './MusicXML';
import { PrerenderedPhraseData, PrerenderedVoiceEntry } from 'Types';

const END_PHRASE_LEFT = 'END_PHRASE_LEFT',
      END_PHRASE_WIDTH = 'END_PHRASE_WIDTH';


export class Iterator {
  private cursorData: PrerenderedPhraseData[];
  phraseUuid: string | undefined;
  private indx: number = 0;
  currentMeasureIndex: number = 0;
  private endReached: boolean = false;
  currentTimeStamp: number = 0;
  noteLocationCache: Map<string, number> = new Map()
  svg: HTMLElement | null = null;

  constructor(cursorData: PrerenderedPhraseData[], phraseUuid: string | undefined) {
    this.cursorData = cursorData
    this.phraseUuid = phraseUuid;
    if(cursorData.length) {
      // console.log(phraseUuid)
      // console.log(cursorData)
      this.currentMeasureIndex = cursorData[0].currentMeasureIndex
      this.currentTimeStamp = cursorData[0].currentTimestamp
    } else {
     this.endReached = true;
    }
  }

  next() {
    this.indx += 1;
    if(this.indx >= this.cursorData.length) {
      this.endReached = true;
    } else {
      this.currentMeasureIndex = this.cursorData[this.indx].currentMeasureIndex;
      this.currentTimeStamp = this.cursorData[this.indx].currentTimestamp;
    }
  }

  public get EndReached(): boolean {
    return this.endReached;
  }
  
  public get CurrentMeasureIndex(): number {
    return this.currentMeasureIndex;
  }

  public get CurrentVoiceEntries(): PrerenderedVoiceEntry[]{
    return this.cursorData[this.indx].currentVoiceEntries;
  }

  public left():  number | undefined {
    // returns the equivalent "left" value of the current cursor iterator location in the
    // phrase SVG

    if(this.EndReached) {
      if(this.phraseUuid) {
        if(!this.svg ) {
          this.svg = document.getElementById(this.phraseUuid)
        }
        let left = this.noteLocationCache.get(END_PHRASE_LEFT);
        let width = this.noteLocationCache.get(END_PHRASE_WIDTH);
        if(this.svg && (!left || !width)) {
          left = this.svg.getBoundingClientRect().left;
          this.noteLocationCache.set(END_PHRASE_LEFT, left);
          width = this.svg.getBoundingClientRect().width;
          this.noteLocationCache.set(END_PHRASE_WIDTH, width);
        }
        if(left && width) {
          return left + width;
        } else {
          console.error("LEFT OR WIDTH NOT FOUND")
          console.log("left: " + left)
          console.log("width: " + width)
        }

        if(!this.svg) {
          console.warn("no SVG found for phrase id: " + this.phraseUuid)
        }
      } else {
        console.warn("no phrase id defined in Phrase iterator - this should only happen if phrases are rendered in browser")

      }
    } else {
      // best option here is to try to find the first non rest note because whole note long rests will be in
      // the middle of the measure, which throws off animation.
      let noteIndx = 0, voiceEntryIndex = 0;
      let note = this.cursorData[this.indx].currentVoiceEntries[0].Notes[0]
      let shortestNoteLength: number | undefined = undefined;
      while(voiceEntryIndex < this.cursorData[this.indx].currentVoiceEntries.length) {
        while(noteIndx < this.cursorData[this.indx].currentVoiceEntries[voiceEntryIndex].Notes.length) {
          const newNote = this.cursorData[this.indx].currentVoiceEntries[voiceEntryIndex].Notes[noteIndx]
          if(shortestNoteLength === undefined || 
            (newNote.length <= shortestNoteLength)) {
              // typically rests will be later in the measure even if they are the same length (i.e. dotted half note vs 3/4 whole note rest)
              if(newNote.isRest && !note.isRest && newNote.length == shortestNoteLength) {
                break;
              } else {
                note = this.cursorData[this.indx].currentVoiceEntries[voiceEntryIndex].Notes[noteIndx]
                shortestNoteLength = note.length;
              }
             
          }
          noteIndx += 1;
        }
        noteIndx = 0;
        voiceEntryIndex += 1;
      }
      const currentNoteId: string = note?.graphics[0]?.staveNote?.id;
      if(currentNoteId) {
        let left = this.noteLocationCache.get(currentNoteId);
        if(!left) {
          const element = document.getElementById(currentNoteId)
          left = element?.getBoundingClientRect()?.left
          if(left) {
            this.noteLocationCache.set(currentNoteId, left);
          }
        }
        return left
      }
      return 0;
    }
  }

  public clearCache() {
    this.noteLocationCache = new Map<string, number>()
  }

  public resetIterator() {
    this.indx = 0;
    if(this.cursorData.length) {
      this.currentMeasureIndex = this.cursorData[0].currentMeasureIndex
      this.currentTimeStamp = this.cursorData[0].currentTimestamp
      this.endReached = false;
    } else {
     this.endReached = true;
    }
  }

  
}

class Phrase {
  musicXML: MusicXML;
  svgURL: string | undefined;
  svgTimesigURL: string | undefined;
  cursorData: PrerenderedPhraseData[] | undefined;
  cursorDataTimesig: PrerenderedPhraseData[] | undefined;
  iterator: Iterator | undefined
  timesigIterator: Iterator | undefined
  uuid: string | undefined
  startTimestamp: number = 0;

  // This will at some point contain all tags
  constructor(uuid: string | undefined, musicXML: MusicXML, svgURL: string | undefined, svgTimesigURL: string | undefined, cursorData: PrerenderedPhraseData[] | undefined, cursorDataTimesig: PrerenderedPhraseData[] | undefined) {
    this.uuid = uuid;
    this.musicXML = musicXML;
    this.svgURL = svgURL;
    this.svgTimesigURL = svgTimesigURL;
    this.cursorData = cursorData;
    this.cursorDataTimesig = cursorDataTimesig;
    if(cursorData) {
      // console.log("cursor data: ", cursorData)
      this.iterator = new Iterator(cursorData, uuid);
    }
    if(cursorDataTimesig) {
      // console.log("cursor data timesig: ", cursorDataTimesig)
      this.timesigIterator = new Iterator(cursorDataTimesig, uuid);
    }
  }

  public setStartTimestamp(startTimestamp: number) {
    this.startTimestamp = startTimestamp
  }


}

export default Phrase;