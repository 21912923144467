
import { FC, useEffect } from 'react'
import { useAuthenticationContext } from 'Contexts/Authentication';
import { Box } from '@mui/material';
import { CustomCircularProgress } from 'Components/StyledComponents';
import { useNavigate } from "react-router-dom";
import ColoredLogo from '../../assets/images/ColoredLogo.png';
import pianoBackground from '../../assets/images/pianoBackground.png';
import { useSelector } from 'react-redux'
import { MainAppReducer } from 'Types';
import GoBackButton from 'Components/GoBackButton';
import SignUp from './SignUp'
import { useLocation } from 'react-router-dom';
import { useAWSRumContext } from 'Contexts/AWSRum';
import { MUSEFLOW_OFF_WHITE_1 } from 'Components/Colors';
import {useMediaQuery} from '@mui/material';
import IntroFlowBack from 'Components/IntroFlowBack';
import Logo from 'Components/Logo';
import { AuthReducer } from 'Types/AuthTypes';

const Signup: FC<{}> = () => {
    const { isAuthenticated, processPending, setPendingEmailOrPhone, setPending } = useAuthenticationContext();
    const navigate = useNavigate(); 
    const { userLevelData } = useSelector((state: MainAppReducer) => state.mainAppReducer)
    const auth = useSelector((state: AuthReducer) => state.authReducer)

    const matchesHeight = useMediaQuery('(max-height:800px)');
    const matchesWidth = useMediaQuery('(max-width:750px)');


    useEffect(()=>{
      if(isAuthenticated && auth.jwtToken) {
        setPendingEmailOrPhone(undefined);
        if(userLevelData.length) {
          navigate('/roadmap')
        } else { 
          navigate('/welcome')
        }
      }
    },[isAuthenticated, navigate, setPendingEmailOrPhone, auth.jwtToken, userLevelData])
  

    return (
      <Box className="page-div-styles">
        <Box className="filter-div-styles">
          <Box className="sign-in-div-styles">
          <IntroFlowBack/>
            <Logo/>
            {
              processPending &&
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CustomCircularProgress />
              </Box>
            }
            <Box>

            </Box>
              <h3 style={{marginTop: '50px', marginBottom: 0}}>Create an Account</h3>
              <SignUp
                goBackFn={()=>navigate('/signin')}
              />
          </Box>
        </Box>
      </Box>      
  )
}

export default Signup;
