

export interface EventState {
  sessionId: string,
}

export interface EventReducer {
  eventReducer: EventState,
}

export enum EventType {
  VisitEvent = "VisitEvent",
  SignInEvent = "SignInEvent",
  MidiConnectionSuccessEvent = "MidiConnectionSuccessEvent",
  MidiConnectionFailureEvent = 'MidiConnectionFailureEvent',
  LevelPlayingEvent = 'LevelPlayingEvent',
  TutorialStartedEvent = 'TutorialStartedEvent',
  TutorialPageViewEvent = 'TutorialPageViewEvent',
  TutorialSkippedEvent = 'TutorialSkippedEvent',
  MobileBlockedEvent = 'MobileBlockedEvent',
  PhraseCompletedEvent = 'PhraseCompletedEvent',
  SafariBlockedEvent = 'SafariBlockedEvent',
  UserSystemDetailsEvent = 'UserSystemDetailsEvent',
}

export enum EventActionTypes {
  SET_SESSION_ID = "SET_SESSION_ID",
  VISIT_EVENT_ACTION = 'VISIT_EVENT_ACTION',
  SIGN_IN_EVENT_ACTION = "SIGN_IN_EVENT_ACTION",
  MIDI_CONNECTION_SUCCESS_EVENT_ACTION = "MIDI_CONNECTION_SUCCESS_EVENT_ACTION",
  MIDI_CONNECTION_FAILURE_EVENT_ACTION = 'MIDI_CONNECTION_FAILURE_EVENT_ACTION',
  LEVEL_PLAYING_EVENT_ACTION = 'LEVEL_PLAYING_EVENT_ACTION',
  TUTORIAL_PAGE_VIEW_EVENT_ACTION = 'TUTORIAL_PAGE_VIEW_EVENT_ACTION',
  TUTORIAL_SKIPPED_EVENT_ACTION = 'TUTORIAL_SKIPPED_EVENT_ACTION',
  MOBILE_BLOCKED_EVENT_ACTION = 'MOBILE_BLOCKED_EVENT_ACTION',
  TUTORIAL_STARTED_EVENT_ACTION = 'TUTORIAL_STARTED_EVENT_ACTION',
  PHRASE_COMPLETED_EVENT_ACTION = 'PHRASE_COMPLETED_EVENT_ACTION',
  SAFARI_BLOCKED_EVENT_ACTION = 'SAFARI_BLOCKED_EVENT_ACTION',
  USER_SYSTEM_DETAILS_EVENT_ACTION = 'USER_SYSTEM_DETAILS_EVENT_ACTION',
}

export type BaseEvent = {
  datetime: string;
  analytics_session_id: string;
}

export type VisitEvent = {
  event_type: EventType.VisitEvent,
}

export type SignInEvent = {
  event_type: EventType.SignInEvent
}

export type MidiConnectionSuccessEvent = {
  event_type: EventType.MidiConnectionSuccessEvent,
}

export type MidiConnectionFailureEvent = {
  event_type: EventType.MidiConnectionFailureEvent,
}

export type LevelPlayingEvent = {
  event_type: EventType.LevelPlayingEvent,
  playTime: number,
  levelNumber: number,
  tierNumber: number,
  play_session_id: string
}

export type TutorialStartedEvent = {
  event_type: EventType.TutorialStartedEvent,
  levelNumber: number,
}

export type TutorialPageViewEvent = {
  event_type: EventType.TutorialPageViewEvent,
  levelNumber: number,
  pageNumber: number
}

export type TutorialSkippedEvent =  {
  event_type: EventType.TutorialSkippedEvent
  levelNumber: number,
}

export type MobileBlockedEvent =  {
  event_type: EventType.MobileBlockedEvent
}

export type PhraseCompletedEvent = {
  event_type: EventType.PhraseCompletedEvent,
  midiData: string,
  userPlayData: string,
  phraseId: string
}

export type SafariBlockedEvent = {
  event_type: EventType.SafariBlockedEvent;
}

export type UserSystemDetailsEvent =  {
  event_type: EventType.UserSystemDetailsEvent;
  browser?: string;
  browserType?: string;
  os: string;
  screenWidth: number;
  screenHeight: number
  userAgent?: string;
  platform: string;
  browserVersion?: string
}

export interface SetSessionId {
  type: EventActionTypes.SET_SESSION_ID;
}

export interface VisitEventAction {
  type: EventActionTypes.VISIT_EVENT_ACTION;
  payload: VisitEvent
}

export interface SignInEventAction {
  type: EventActionTypes.SIGN_IN_EVENT_ACTION;
  payload: SignInEvent
}

export interface MidiConnectionSuccessEventAction {
  type: EventActionTypes.MIDI_CONNECTION_SUCCESS_EVENT_ACTION;
  payload: MidiConnectionSuccessEvent
}

export interface MidiConnectionFailureEventAction {
  type: EventActionTypes.MIDI_CONNECTION_FAILURE_EVENT_ACTION;
  payload: MidiConnectionFailureEvent
}

export interface LevelPlayingEventAction {
  type: EventActionTypes.LEVEL_PLAYING_EVENT_ACTION;
  payload: LevelPlayingEvent;
}

export interface TutorialStartedEventAction {
  type: EventActionTypes.TUTORIAL_STARTED_EVENT_ACTION;
  payload: TutorialStartedEvent;
}

export interface TutorialPageViewEventAction {
  type: EventActionTypes.TUTORIAL_PAGE_VIEW_EVENT_ACTION;
  payload: TutorialPageViewEvent;
}

export interface TutorialSkippedEventAction {
  type: EventActionTypes.TUTORIAL_SKIPPED_EVENT_ACTION;
  payload: TutorialSkippedEvent;
}

export interface MobileBlockedEventAction {
  type: EventActionTypes.MOBILE_BLOCKED_EVENT_ACTION;
  payload: MobileBlockedEvent;
}

export interface PhraseCompletedEventAction {
  type: EventActionTypes.PHRASE_COMPLETED_EVENT_ACTION;
  payload: PhraseCompletedEvent;
}

export interface SafariBlockedEventAction {
  type: EventActionTypes.SAFARI_BLOCKED_EVENT_ACTION;
  payload: SafariBlockedEvent;
}

export interface UserSystemDetailsEventAction {
  type: EventActionTypes.USER_SYSTEM_DETAILS_EVENT_ACTION;
  payload: UserSystemDetailsEvent;
}

export type EventStateActions = 
  | SetSessionId

export type EventActions =
  | SignInEventAction
  | MidiConnectionSuccessEventAction
  | MidiConnectionFailureEventAction
  | LevelPlayingEventAction
  | TutorialStartedEventAction
  | TutorialPageViewEventAction
  | TutorialSkippedEventAction
  | MobileBlockedEventAction
  | PhraseCompletedEventAction
  | SafariBlockedEventAction
  | UserSystemDetailsEventAction
  | VisitEventAction