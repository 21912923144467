import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { UpdateProfileData } from "Types";
import { CustomTextField, CustomButton } from "Components/StyledComponents"
import { FormikProps } from "formik";
import { validateEmailorPhone } from "./SignUpForm";
import * as yup from "yup";


export type UpdateProfileFormProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholders?: UpdateProfileData,
  successMessage?: String
} & FormikProps<UpdateProfileData>;

export const updateProfileFormSchema = yup.object().shape({
  name: yup
    .string(),
  emailOrPhone: yup
    .string()
    .test("validateEmailorPhone", "Must be an email or phone number", validateEmailorPhone)
});

const UpdateProfileForm: React.FC<UpdateProfileFormProps> = (
  props: UpdateProfileFormProps
) => {
  const { handleChange, placeholders, values, errors, successMessage } = props;
  
  return (
    <div>

      <CustomTextField
        sx={{marginBottom: '20px'}}
        id="name"
        name="name"
        type="text"
        onChange={handleChange}
        label="Full Name"
        value={values.name || ""}
        error={!!errors.name}
        helperText={errors.name}
        fullWidth
        placeholder={placeholders ? placeholders.name : undefined}
        InputLabelProps={placeholders && placeholders?.name ? {
          shrink: true,
        } : undefined }
      />

      <CustomTextField
        sx={{marginBottom: '20px'}}
        onChange={handleChange}
        id="emailOrPhone"
        name="emailOrPhone"
        type="emailOrPhone"
        label="Email or Phone Number"
        value={values.emailOrPhone || ""}
        error={!!errors.emailOrPhone}
        helperText={errors.emailOrPhone}
        fullWidth
        placeholder={placeholders ? placeholders.emailOrPhone : undefined}
        InputLabelProps={placeholders && placeholders?.emailOrPhone ? {
          shrink: true,
        } : undefined }
      />

      <Box>

        <Typography color="error" variant="h6">
          {errors.unknown}
        </Typography> 
        <Typography color="green">
          {successMessage}
        </Typography> 
      </Box>

      <CustomButton
        variant="contained"
        type="submit"
        sx={{
          marginBottom: '20px',
          '&:hover': {
            background: "rgb(202,124,98,1.0)",
            cursor: 'pointer',
            transition: '0.3s',
            },
          "&.Mui-disabled": {
            backgroundColor: '#767C8C',
          },
        }}
        disabled={!(values.emailOrPhone || values.name)}
      >
        SAVE
      </CustomButton> 

    </div>
  );
};

export default UpdateProfileForm;