import React, { useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { SignInData } from "Types";
import { CustomTextField, CustomButton } from "Components/StyledComponents"
import { FormikErrors, FormikProps } from "formik";
import { validateEmailorPhone } from "./SignUpForm";
import * as yup from "yup";

export type SignInFormProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  forgotPasswordNavFunction: ()=>void
} & FormikProps<SignInData>;

export const signInFormSchema = yup.object().shape({
  emailOrPhone: yup
    .string()
    .test("emailOrPhoneNumber", "Must be an email or a phone number that starts with a country code, i.e. +1 for U.S.",validateEmailorPhone)
    .required("Email or Phone Number is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
});


const SignInForm: React.FC<SignInFormProps> = (
  props: SignInFormProps
) => {
  const { handleChange, values, errors, forgotPasswordNavFunction } = props;

  return (
    <Box>
      <CustomTextField
        sx={{maxWidth: '88%', marginBottom: '20px'}}
        onChange={handleChange}
        id="emailOrPhone"
        name="emailOrPhone"
        type="emailOrPhone"
        label="Email or Phone Number"
        value={values.emailOrPhone || ""}
        error={!!errors.emailOrPhone}
        helperText={errors.emailOrPhone}
        fullWidth
      />
      <CustomTextField
        sx={{maxWidth: '88%', marginBottom: '0px'}}
        onChange={handleChange}
        id="password"
        name="password"
        type="password"
        label="Password"
        value={values.password || ""}
        error={!!errors.password}
        helperText={errors.password}
        fullWidth
      />

      <Box>
        { 
        errors.unknown && 
        <Typography color="error" variant="h6">
          {errors.unknown}
        </Typography> 
        }
      </Box>

      <Box 
          sx={{
            textAlign: 'end',
            lineHeight: 0,
            marginTop: '0px',
          }}
        >
          <Button 
            variant="text" 
            onClick={()=>{forgotPasswordNavFunction()}} 
            sx={{
              color: "#CED0D4",
              textAlign: 'center',
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Forgot Password?
          </Button>
          
        </Box>

      <Box sx={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
        <CustomButton
          variant="contained"
          type="submit"
        >
          SIGN IN
        </CustomButton> 
      </Box>

    </Box>
  );
};

export default SignInForm;