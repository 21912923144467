import React from 'react';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { UseStateError } from 'Utils/Errors';
import { useAuthenticationContext } from './Authentication';
import { useSelector } from 'react-redux';
import { AuthReducer } from 'Types/AuthTypes';

export type AWSRumStateType = {
  awsRum: AwsRum | null
};

export const AWSRumContext = React.createContext<(AWSRumStateType) | null>(null);

export const useAWSRumContext: () => AWSRumStateType = () => {
  const state = React.useContext(AWSRumContext);
  if (!state) {
    throw new UseStateError(useAWSRumContext, AWSRumProvider);
  }

  return {
    ...state
  };
};

export type AWSRumProviderProps = {
  children?: React.ReactNode;
};


export const AWSRumProvider: React.FC<AWSRumProviderProps> = ({
  children
}: AWSRumProviderProps) => {

  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const [awsRum, setAWSRum] = React.useState<AwsRum | null>(null)
  
  React.useEffect(() => {
    try {
      if(process.env.REACT_APP_NODE_ENV === 'local') {
        throw new Error('Do not load RUM in local env')
      }
      const cognitoGuestRoleArn = process.env.REACT_APP_COGNITO_GUEST_ROLE_ARN;
      const awsRumIdentityPoolId = process.env.REACT_APP_RUM_IDENTITY_POOL_ID;
      const awsRumSessionSampleRate = process.env.REACT_APP_RUM_SESSION_SAMPLE_RATE;
      let awsRumApplicationId = process.env.REACT_APP_RUM_APPLICATION_ID;
      let applicationVersion = process.env.REACT_APP_APPLICATION_VERSION;

      if(!awsRumSessionSampleRate) {
        console.warn("AWS RUM sample rate not set")
      }
      if(!awsRumApplicationId) {
        throw new Error("AWS RUM application ID not set")
      }
      if(!applicationVersion) {
        console.warn("AWS RUM application version not set")
        applicationVersion = '1.0.0'
      }
      const sessionSampleRate = awsRumSessionSampleRate ? parseInt(awsRumSessionSampleRate) : .1
      const config: AwsRumConfig = {
        allowCookies: true,
        guestRoleArn: cognitoGuestRoleArn,
        identityPoolId: awsRumIdentityPoolId,
        sessionSampleRate: sessionSampleRate,
        enableXRay: true,
        telemetries: [
          'errors', 
          [ "performance", {recordAllTypes: ['document', 'script', 'other']} ],
          [ 'http', {
            recordAllRequests: true,
            urlsToExclude: [
              new RegExp('/audio-samples/.+'),
              new RegExp('https://dataplane.rum.us-west-2.amazonaws.com/.+')
            ]
          } ]],
        eventPluginsToLoad: []
      };
      
      setAWSRum(new AwsRum(
        awsRumApplicationId as string, // we want this to fail if it isn't set
        applicationVersion,
        'us-west-2',
        config
      ))
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }, [])

  return (
    <AWSRumContext.Provider 
      value={{
        awsRum
      }}
    >
      {children}
    </AWSRumContext.Provider>
  )
}