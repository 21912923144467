import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthenticationContext } from 'Contexts/Authentication';
import { Box } from '@mui/material';
import { CustomCircularProgress } from 'Components/StyledComponents';
import PaymentFlow from './Payment/PaymentFlow'

type ProtectedLayoutProps = {
  children: React.ReactNode;
};
const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ children }) => {
  return (
    <>
      <PaymentFlow />
      {children}
    </>
  );
};

type ProtectedRouteProps = {
  redirectPath: string,
} & React.PropsWithChildren

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({children, redirectPath}) => {
  const { isAuthenticated, processPending } = useAuthenticationContext();

  if (!processPending && !isAuthenticated) {
    return <Navigate to={redirectPath} replace={true} />;
  }

  return ( 
    <>
      {processPending ? (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CustomCircularProgress/>
        </Box>
      ) : (
        <ProtectedLayout>{children}</ProtectedLayout>
      )}
    </>
  )
};

export default ProtectedRoute;