import { Auth } from "aws-amplify";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getRum } from "./AwsRum";

const rum = getRum()
export default class AxiosRetrier {

  constructor() {
    Auth.currentAuthenticatedUser().then((user:any)=>{
      try{
        rum?.addSessionAttributes({
          "user_id": user?.attributes?.sub,
        })
      } catch(err){}
    })
  }

  async callMethodMaybeData<T = any, R = AxiosResponse<T, any>>(
    method: 
    (<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>) => Promise<R>) |
    (<R = AxiosResponse<any, any>, D = any>(url: string, config?: AxiosRequestConfig<D>) => Promise<R>) | 
    (<R = AxiosResponse<any, any>, D = any>(url: string, data?: D | undefined, config?: AxiosRequestConfig<D>) => Promise<R>), 
    url: string | undefined,
    data?: any | undefined,
    config?: AxiosRequestConfig<any>): Promise<R> {
    if(!data && !url && config) {
      return (method as (<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>) => Promise<R>))(config)
    }
    if(url && data) {
      return (method as (<R = AxiosResponse<any, any>, D = any>(url: string, data?: D | undefined, config?: AxiosRequestConfig<D>) => Promise<R>))(url, data, config)
    }
    if(url) {
      return (method as (<R = AxiosResponse<any, any>, D = any>(url: string, config?: AxiosRequestConfig<D>) => Promise<R>))(url, config)
    }
    throw new Error("Unrecognized method axios configuration. One of url, data, or config must be defined.")
  }

  async retryRequest2<T = any, R = AxiosResponse<T, any>>(
    method: 
      (<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>) => Promise<R>) |
      (<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>) => Promise<R>) | 
      (<R = AxiosResponse<any, any>, D = any>(url: string, data?: D | undefined, config?: AxiosRequestConfig<D>) => Promise<R>), 
    url: string | undefined, data?: any | undefined, config?: AxiosRequestConfig<any>): Promise<R> {
    try {
      const response = await this.callMethodMaybeData(method, url, data, config)
      return Promise.resolve(response as any);
    } catch(err) {
      if(err instanceof AxiosError && err?.response?.status === 401) {
        console.log("caught 401")
        console.log(err.status)
        const user = await Auth.currentAuthenticatedUser();
        rum?.addSessionAttributes({
          "user_id": user?.attributes?.sub,
        })
        if(config?.headers) {
          config.headers['Authorization'] = `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`
        }
        return this.callMethodMaybeData(method, url, data, config)
      } else if(err instanceof AxiosError && err.status !== undefined && err?.response?.status && err?.response?.status >= 500) {
        console.log("caught 5XX")
        rum?.recordError(err)
        return this.callMethodMaybeData(method, url, data, config)
      } else {
        const user = await Auth.currentAuthenticatedUser();
        rum?.recordError(err)
        console.error("caught axios error", err)
        throw err;
      }
    }
  }
  
  get<T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return this.retryRequest2(axios.get, url, undefined, config)
  }
  delete<R = AxiosResponse<any, any>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return this.retryRequest2(axios.delete, url, undefined,  config)
  }
  head<R = AxiosResponse<any, any>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return this.retryRequest2(axios.head, url, undefined,  config)
  }
  options<R = AxiosResponse<any, any>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>{
    return this.retryRequest2(axios.options, url, undefined,  config)
  }
  post<R = AxiosResponse<any, any>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>{
    return this.retryRequest2(axios.post, url, data, config)
  }
  put<R = AxiosResponse<any, any>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>{
    return this.retryRequest2(axios.put, url, data, config)
  }
  patch<R = AxiosResponse<any, any>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>{
    return this.retryRequest2(axios.patch, url, data, config)
  }
  request<R = AxiosResponse<any, any>, D = any>(config?: AxiosRequestConfig<D>): Promise<R>{
    return this.retryRequest2(axios.request, undefined, undefined, config)
  }
}
