import React, {Component, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';  
import { detect, OperatingSystem } from 'detect-browser';
import { AppState, MainAppReducer } from 'Types';
import { CustomCircularProgress } from 'Components/StyledComponents';
// import Welcome from './Containers/Welcome/Welcome';
import Welcome from 'Containers/TrialWelcome/Welcome';
import Cutscene from './Containers/Cutscene/Cutscene';
import Tutorial from './Containers/Tutorial/Tutorial';
import Introduction from './Containers/Introduction/Introduction';
import Lesson from './Containers/Lesson/Lesson';
import Progress from './Containers/Progress/Progress';
import Questionnaire from './Containers/Questionnaire/Questionnaire';
import Credits from './Containers/Credits/Credits';
import Signin from './Containers/SignIn';
import Signup from './Containers/SignUp';
import Forgot from './Containers/Forgot/Forgot';
import Plan from './Containers/Plan/Plan';
import Payment from './Containers/Payment/Payment';
import Roadmap from './Containers/Roadmap/Roadmap';
import Help from './Containers/Help/Help';
import Settings from './Containers/Settings/Settings';
import ConnectMidi from 'Containers/TrialWelcome/ConnectMidi';
import SoundOutput from 'Containers/TrialWelcome/SoundOutput';
import TrialWelcome from './Containers/TrialWelcome/TrialWelcome';
import ErrorPage from './Containers/ErrorPage/ErrorPage';
import Verification from './Containers/Verification';
import { useState } from 'react'
import ProtectedRoute from 'Containers/ProtectedRoute';
import { Amplify, Auth, Analytics } from 'aws-amplify';
// import { initializeMidiInput } from 'Utils/MidiInput';
import { midiPitchToFrequency } from 'Utils';
import { AwsRum } from 'aws-rum-web';
import {AWSRumStateType, AWSRumContext, useAWSRumContext} from 'Contexts/AWSRum';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom' //Switch
import { SafariBlockingScreen, MobileBlockingScreen } from 'Containers/SafariBlockingScreen';
import { isSafariBrowser, isMobileBrowser, isCompatibleAndroid } from 'Utils/UserAgent';
import useMediaQuery from '@mui/material/useMediaQuery';

//import {requestClientDataRequest} from './Actions/app'
import * as appActions from './Actions/app'
import * as eventActions from './Actions/events';
import * as authActionTypes from 'Actions/auth'
import LevelDescription from 'Containers/LevelDescription/LevelDescription';
import { useAuthenticationContext } from 'Contexts/Authentication';
import { MIDIVal, MIDIValInput } from '@midival/core';
import * as Tone from 'tone';
import { TONE_SAMPLES } from 'Utils/Constants';
import TimeKeeper from 'Models/TimeKeeper';
import EventStream from 'Models/EventStream';
import { TimingOffsetsConfig } from 'Models/EventStream';
import { useNavigate } from "react-router-dom";
import { MidiProvider } from 'Contexts/MidiContext';
import { ALLOWED_PATHS_ANY_PLATFORM } from 'Utils/Constants';

import { RootState } from './Reducers';
import { Store } from 'redux';
import { saveState, loadState } from 'Utils/LocalStorage';
import ReactGA from "react-ga4";
import { authReducer } from 'Reducers/authReducer';
import { AuthReducer } from 'Types/AuthTypes';
import { EventReducer } from 'Types/EventTypes';

const browser = detect();

const region = process.env.REACT_APP_COGNITO_AWS_REGION;
const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
// const cognitoGuestRoleArn = process.env.REACT_APP_COGNITO_GUEST_ROLE_ARN;
const awsIdentityPoolId = process.env.REACT_APP_RUM_IDENTITY_POOL_ID;
let awsPinpointApplicationId = process.env.REACT_APP_PINPOINT_APPLICATION_ID;
// let applicationVersion = process.env.REACT_APP_APPLICATION_VERSION;
const oauthDomain = process.env.REACT_APP_OAUTH_REDIRECT_URL
const ganayticsMeasurementId = process.env.REACT_APP_G_ANALYTICS_MEASUREMENT_ID
const timeKeeper: TimeKeeper = new TimeKeeper()
const midiStream: EventStream = new EventStream(timeKeeper, new TimingOffsetsConfig())
 
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const awsConfig = {
  Auth: {
    region: region,
    userPoolId: cognitoUserPoolId,
    userPoolWebClientId: cognitoClientId,
    identityPoolId: awsIdentityPoolId,
    identityPoolRegion: region,
    oauth: {
      domain: oauthDomain,
      // domain: 'localhost',
      scope: [
        'phone',
        'email',
        'aws.cognito.signin.user.admin',
        'profile', 
        'openid'
      ],
      // redirectSignIn: 'http://localhost:3000/register/',
      // redirectSignOut: 'http://localhost:3000/',
      redirectSignIn: `${window.location.origin}/signin/`,
      redirectSignOut: `${window.location.origin}/`,
      responseType: 'code',
      // urlOpener: (url:string) => {
      //   const left = (window.screen.width - 600 ) / 2;
      //   const top = (window.screen.height - 549 ) / 2;
      //   const windowProxy = window.open( url, "center window",
      //     `resizable = yes, width=600,height=549,top=${top},left=${left}`);
      //   return Promise.resolve(windowProxy)
      // }
  
    }
  },
  Analytics: {
    AWSPinpoint: {
      appId: awsPinpointApplicationId,
      region: region
    },
  }
}
if(process.env.REACT_APP_NODE_ENV == 'prd' && ganayticsMeasurementId) {
  ReactGA.initialize(ganayticsMeasurementId);
}


// Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [
//   localRedirectSignIn,
//   productionRedirectSignIn,
// ] = awsConfig.oauth.redirectSignIn.split(',');

// const [
//   localRedirectSignOut,
//   productionRedirectSignOut,
// ] = awsConfig.oauth.redirectSignOut.split(',');

// const updatedAwsConfig = {
//   ...awsConfig,
//   oauth: {
//     ...awsConfig.oauth,
//     redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
//     redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
//   }
// }

Amplify.configure(awsConfig);

// let awsRum: AwsRum | null = null

// try {
//   if(!awsRumSessionSampleRate) {
//     console.warn("AWS RUM sample rate not set")
//   }
//   if(!awsRumApplicationId) {
//     throw new Error("AWS RUM application ID not set")
//   }
//   if(!applicationVersion) {
//     console.warn("AWS RUM application version not set")
//     applicationVersion = '1.0.0'
//   }
//   const sessionSampleRate = awsRumSessionSampleRate ? parseInt(awsRumSessionSampleRate) : .1
  
//   const config: AwsRumConfig = {
//     allowCookies: true,
//     enableXRay: true,
//     guestRoleArn: cognitoGuestRoleArn,
//     identityPoolId: awsRumIdentityPoolId,
//     sessionSampleRate: sessionSampleRate,
//     telemetries: ['errors', 'performance',  [ 'http', { addXRayTraceIdHeader: true } ]],
//     eventPluginsToLoad: []
//   };
  
//   awsRum = new AwsRum(
//     awsRumApplicationId as string, // we want this to fail if it isn't set
//     applicationVersion,
//     'us-west-2',
//     config
//   );
// } catch (error) {
//   // Ignore errors thrown during CloudWatch RUM web client initialization
// }


interface AppProps {
  store: any;//Store<RootState>;
  // store: Store<RootState>;
}
// function App({ store }) {
const App: React.FC<AppProps> = ({ store }) => {

  // If the user hits refresh, on /lesson for example, we want to reload page with all of their current data
  // So we will want at top level here to set state from local store. (and then do whatever follow-up queries are necessary if there are any right off the bat)
  // State data should be: Current phrase (like s3 url), current lesson, current Tempo, ...
  const dispatch = useDispatch()
  const { user } = useAuthenticationContext();
  const location = useLocation();
  const navigate = useNavigate();
  const sampler = React.useMemo(() => new Tone.Sampler(TONE_SAMPLES).toDestination(), [])
  const [initialized, setInitialized] = useState(false);

  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const {sessionId} = useSelector((state: EventReducer) => state.eventReducer)

  const { awsRum } = useAWSRumContext();

  const [playToneSound, SetplayToneSound] = useState(true)

  function getOperatingSystemBackup() {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
  
    return operatingSystem;
  }

  // useEffect(() => {
  //   if(auth.jwtToken && !data.userData?.user_id) {
  //     dispatch(appActions.authenticatedStartup({
  //       authToken: auth.jwtToken,
  //       user
  //     }))
  //   }
  // }, [auth.jwtToken, data.userData?.user_id])

  useEffect(() => {

    if(auth.jwtToken && data.userData) { // only call once userData row has been created & is present
      dispatch(appActions.getSubscriptionStatus({
        authToken: auth.jwtToken,
      }))
    }
    if(auth.jwtToken && !data.userData?.user_id) {
      dispatch(appActions.authenticatedStartup({
        authToken: auth.jwtToken,
        user
      }))
    }

  }, [auth.jwtToken])

  // It doesn't seem like the context listener always gets called so set here as well just to be
  useEffect(() => {
    if(user?.signInUserSession?.accessToken?.jwtToken) { 
      dispatch(authActionTypes.setToken({token: user?.signInUserSession?.accessToken?.jwtToken}))
    }
  }, [user?.signInUserSession?.accessToken?.jwtToken])

  useEffect(() => {
    if(auth.jwtToken && sessionId && !initialized) { // only call once userData row has been created & is present
      if(auth.user?.attributes?.sub) {
        awsRum?.addSessionAttributes({
          "user_id": auth.user?.attributes.sub,
          "analytics_session_id": sessionId
        })
      }
      dispatch(eventActions.visitEventAction())
      dispatch(eventActions.userSystemDetailsEventAction(
        browser?.os || 'Unknown',
        window.innerWidth,
        window.innerHeight,
        process.env.REACT_APP_PLATFORM || 'unknown',
        navigator?.userAgent,
        browser?.name,
        browser?.type,
        browser?.version || undefined
      ))  
      // we only want this to fire when the page loads. but we need the jwt token, so it needs to
      // be in the useEffect triggers. So, that means it would fire when the auth token changes. But
      // we only want to fire it once. So, intialized keeps track of this happening only once.
      setInitialized(true)
    }
  }, [auth.jwtToken, sessionId, initialized,  data.userData?.user_id])
  
  useEffect(() => {
    let previousLocation = sessionStorage.getItem('previousLocation');
    // in case of refresh; don't set unless changed
    if (previousLocation != location.pathname) {
      sessionStorage.setItem('previousLocation', location.pathname)
    }

    if (process.env.REACT_APP_NODE_ENV == 'prd') {
      const gtag_tracking_id = 'AW-11476609062'
      // Initialize Google Tracking Tag / Analytics (only once)
      if (!window.dataLayer) {
        window.dataLayer = [];
        window.gtag = function() {
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', gtag_tracking_id);
      }
      // Track page views:
      const pagePath = location.pathname + location.search;
      window.gtag('config', gtag_tracking_id, { 'page_path': pagePath });
      awsRum?.recordPageView(location.pathname);
      ReactGA?.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location]);

  useEffect(() => {
    let unsubscribe: any;
    const userId = user?.attributes?.sub
    if (userId) {
      unsubscribe = store.subscribe(() => {
        saveState(userId, {
          mainAppReducer: store.getState().mainAppReducer,
        });
      });
      const loadedState = loadState(userId) // call load, now that we have the user id! 
      if (loadedState) {
        // Now update the store with the user-specific state
        dispatch(appActions.loadUserState(loadedState.mainAppReducer))
      }
    }
    if(user) {
      
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user])

  
  const matchesHeight = useMediaQuery('(min-width:1000px)');
  const matchesWidth = useMediaQuery('(min-height:1000px)');

  // Check paths that allow on any browser
  if (!ALLOWED_PATHS_ANY_PLATFORM.includes(location.pathname)) {
    if (isMobileBrowser() && !isCompatibleAndroid() && !(matchesHeight || matchesWidth)) {
      return <MobileBlockingScreen/>
    }
    if (isSafariBrowser()) {
      return <SafariBlockingScreen/>
    }
  }

  // Using "userData" to check if the state data has loaded and persisted.
  // Without this check; tutorial page and lesson page will error before data is loaded
  // Note that we check "user" well; user may need to go to sign in/up.
  if (user && !data.userData) {
    return (
      <Box>
        <Box sx={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CustomCircularProgress/>
        </Box> 
      </Box>
    )
  }

  return (
      <MidiProvider location={location} navigate={navigate} sampler={sampler}>
        <Routes>
          <Route path='/register' element={<Signup/>} />
          <Route path='/signin' element={<Signin/>} />
          <Route path='/verify' element={<Verification/>} />
          {/* <Route path='/forgot' element={<Forgot/>} /> */}
          {/*  Make sure to use these two (looks pretty good already) */}
          <Route path='/plan' element={<ProtectedRoute redirectPath='/signin'> <Plan/> </ProtectedRoute>} />
          {/* <Route path='/payment' element={<ProtectedRoute redirectPath='/signin'> <Payment/> </ProtectedRoute>} /> */}
          <Route path='/welcome' element={<ProtectedRoute redirectPath='/signin'> <Welcome/>  </ProtectedRoute>} />
          <Route path='/sound-output' element={<ProtectedRoute redirectPath='/signin'> <SoundOutput/> </ProtectedRoute>} />
          <Route path='/connect-midi' element={<ProtectedRoute redirectPath='/signin'> <ConnectMidi/> </ProtectedRoute>} />
          <Route path='/roadmap' element={<ProtectedRoute redirectPath='/signin'> <Roadmap/> </ProtectedRoute>} />
          {/* Still allowing the FAQ route, but will redirect to https://museflow.ai/faq */}
          <Route path='/faq' element={<ProtectedRoute redirectPath='/signin'> <Help/> </ProtectedRoute>} />
          <Route path='/settings' element={<ProtectedRoute redirectPath='/signin'> <Settings/> </ProtectedRoute>} />
          {/* <Route path='/leveldesscription' element={<ProtectedRoute redirectPath='/signin'> <LevelDescription/> </ProtectedRoute>} /> */}
          <Route path='/tutorial' element={<ProtectedRoute redirectPath='/signin'> <Tutorial/> </ProtectedRoute>} />
          <Route path='/introduction' element={<ProtectedRoute redirectPath='/signin'> <Introduction/> </ProtectedRoute>} />
          <Route path='/trial-welcome' element={<ProtectedRoute redirectPath='/signin'> <TrialWelcome/> </ProtectedRoute>} />
          <Route path='/lesson' element={ <ProtectedRoute redirectPath='/signin'><Lesson/></ProtectedRoute>}/>
          <Route path='/404' element={ <ErrorPage/> } />
          <Route path='/' element={ <Navigate to="/roadmap"/>} /> {/* Navigate homepage to roadmap */}
          <Route path='/*' element={ <Navigate to="/404"/>} /> 
        </Routes>
      </MidiProvider>
    
  )
}

//const RootApp: React.FC<AppProps> = ({ store }) => {
const RootApp: React.FC<any> = ({ store }) => {
  return (
    <div className="App">
      <BrowserRouter>
        <App store={store} />
      </BrowserRouter>
    </div>
  );
}
      

interface ErrorBoundaryProps {
  //store: Store<RootState>;
  store: Store<any>;
}

// class ErrorBoundary extends React.Component<{}, {hasError: boolean}> {
class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {

  static contextType = AWSRumContext
  
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    const awsRumContext = (this.context as AWSRumStateType)
    awsRumContext?.awsRum?.recordError(error);
  }
  render() {
    // No need to present error page on every uncaught error
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return (
    //     <ErrorPage />
    //   );
    // } else {
    //   return (
    //     <App/>
    //   )
    // }
    return (
      <RootApp store={this.props.store}/>
    )
  }
}

export default ErrorBoundary;
