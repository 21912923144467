import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import { useAuthenticationContext } from 'Contexts/Authentication';
import GoBackButton from 'Components/GoBackButton';

type VerificationPendingProps = {
  resendFn: (pendingEmail: string) => void,
  goBackFn: () => void
};

const VerificationPending: React.FC<VerificationPendingProps> = (props) => {

  const { resendFn, goBackFn } = props

  const { pendingEmailOrPhone } = useAuthenticationContext();

  return (
    <>
      <Box 
            sx={{
              margin: "20px",
              backgroundColor: 'rgba(57, 94, 227, 0.40)',
            }}
            color="#00C2FF"
          >
            Your registration is pending. Check your email to verify.
            <br></br>
            (If you don't see an email, check your spam folder)
          </Box> 
          <Link 
            sx={{
              textAlign: 'center' as 'center',
              cursor: 'pointer'
            }}
            onClick={()=> {resendFn(pendingEmailOrPhone || "")}}
          >
            Didn't get a link? Click here to resend.
          </Link>
          <Typography 
            sx={{
              margin: "20px"
            }}
            color="rgb(255,255,255,1.0)"
          >
            Registered? Go back and sign in.
          </Typography> 
          <GoBackButton
            onClick={goBackFn}
          >
            Back
          </GoBackButton>
        </>
  )
}

export default VerificationPending;