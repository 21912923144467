export const planPricing = {
  monthly: {
    regular: 7.99,
    withCode: 3.99,
  },
  annually: {
    regular: 79.89,
    withCode: 39.95,
  },
};

export const prevPlanPricing = {
  monthly: {
    regular: 15.99,
    withCode: 7.99,
  },
  annually: {
    regular: 164.99,
    withCode: 82.49,
  },
};

export const numTrialDays = 7

export const discountPercentages = {
  monthly: {
    regular: null,
    withCode: 50,
  },
  annually: {
    regular: 16,
    withCode: 58,
  },
}

// From stripe; should pull from backend endpoint...
export const stripePriceIds = (() => {
  if (process.env.REACT_APP_NODE_ENV == "prd") {
    // These are the LIVE ones:
    return {
      monthly: 'price_1PAi9kJb5DvLdpQFT0Cu0k1z', // old 15.99 price -> 'price_1O7sL2Jb5DvLdpQFy9FZwyhV',
      annually: 'price_1PAi9hJb5DvLdpQFJTIRhZCH', // old 164.99 price -> 'price_1O7sKmJb5DvLdpQF0e27xot3',
    };
  } else {
    // These are the TEST ones:
    return {
      monthly: 'price_1PAi0yJb5DvLdpQFgHozXDo6',//'price_1O7sFDJb5DvLdpQFhp7lJajZ',
      annually: 'price_1PAi8uJb5DvLdpQF41djzTol',//'price_1O7sJBJb5DvLdpQFKEEK8UnK',
    }
  }
})()
// andrew.urbanowicz+36@museflow.ai