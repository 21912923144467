
import { AnySoaRecord } from "dns";
import { AnyKindOfDictionary } from "lodash";
import { compose, merge, get, reduce, tap } from "lodash/fp";
import * as yup from "yup";
import { isNil } from 'lodash';
import { AmplifyAuthError, SignUpData } from 'Types';

 const composeFormErrors = compose(
  reduce(
    (errors: { [x: string]: string }, validationError: yup.ValidationError) =>
      merge(errors, {
        [validationError.path || 'no path']: validationErrorCodeMessageSanitizer(validationError.message)
      }),
    {}
  ),
  get("inner")
);


const ValidateForm = (formSchema: yup.ObjectSchema<any, any, any, any>, values: AnyKindOfDictionary) => 
  formSchema
  .validate(values, { abortEarly: false })
  // For some reason this needs a ".then" to work even if the function is empty.
  .then(() => {}) // eslint-disable-line @typescript-eslint/no-empty-function
  .catch(err => {
    composeFormErrors(err)
  });
  
  const validationErrorCodeMessageSanitizer = (message: string) => {
    if(message.includes("must be a `number` type")) {
      return "Value must be a number"
    } 
    return message
  }
// It appears an error code can be used for multiple types of errors, so we should check 
// them based on both code and message
const AuthErrorCodeMessageMap: {[key:string]: string} = {
  [["UsernameExistsException", "hello world"].toString()]: "An account with that email already exists.",
  [["NotAuthorizedException", "Incorrect username or password."].toString()]:"Incorrect username or password.",
  [["NotAuthorizedException", "Password attempts exceeded"].toString()]:"Password attempts exceeded.",
  [["LimitExceededException", "Attempt limit exceeded, please try after some time."].toString()]:"Attempt limit exceeded, please try after some time.",
  [["CodeMismatchException", "Invalid verification code provided, please try again."].toString()]:"Invalid verification code provided. If this continues, try generating a new one.",
  [["UsernameExistsException", "An account with the given email already exists."].toString()]: "An account with the given email already exists. Try resetting your password.",
  [["UsernameExistsException", "An account with the given phone_number already exists."].toString()]: "An account with the given phone number already exists. Try resetting your password.",
  [["UserNotConfirmedException", "User is not confirmed."].toString()]: "Your account hasn't yet been verified. Please check your phone or email for a verification code.",
  [["ExpiredCodeException", "Invalid code provided, please request a code again."].toString()]: "Invalid code provided, please request a code again."

}

const AuthErrorCodeInputLabelMap: {
  [key: string]: string[];
} = {
  UsernameExistsException: ["emailOrPhone"],
  NotAuthorizedException: ["password", "repeatPassword"],
  LimitExceededException: ["emailOrPhone", "code", "password", "repeatPassword"],
  CodeMismatchException: ["code"],
  ExpiredCodeException: ["code", "password", "repeatPassword"],
  UserNotConfirmedException: ["emailOrPhone"],
};

const handleAuthErrors = (error: AmplifyAuthError): any => {
  let errorLabels, errorMessage;
  if(!AuthErrorCodeInputLabelMap[error.code] || !AuthErrorCodeMessageMap[[error.code, error.message].toString()]) {
    console.error("Unknown error code: " + error.code)
    console.error("Unknown error message: " + error.message)

    errorLabels = ['unknown'];
    errorMessage = 'Unknown Error'
  } else {
    errorLabels = AuthErrorCodeInputLabelMap[error.code]
    errorMessage = AuthErrorCodeMessageMap[[error.code, error.message].toString()] || 'Unkown Error'
  }
  const newErrObj: {[key:string]: string} = {}
  for (const errorLabel of errorLabels ) {
    newErrObj[errorLabel] = errorMessage
  }
  return newErrObj
};


export {
  composeFormErrors,
  ValidateForm,
  // AuthErrorCodeMessageMap,
  handleAuthErrors
}