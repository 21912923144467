import { BaseEvent, EventActions, EventActionTypes, EventReducer, SignInEvent } from "Types/EventTypes"
import * as eventActions from 'Actions/events';
import AxiosRetrier from "Utils/AxiosRetrier"
import {getRum} from 'Utils/AwsRum';
import { all, put, select, takeEvery } from "redux-saga/effects";
import { useSelector } from "react-redux";
import { AugmentedCognitoUser } from "Types";
import { AuthReducer } from "Types/AuthTypes";

const axios = new AxiosRetrier();
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`

const awsRum = getRum()

function* onStartup() {
  yield put(
    eventActions.setSessionId()
  )  
}


function* sendEventToBackend(action: EventActions) {
  const eventsUrl = baseUrl + 'events';
  const {jwtToken} = yield select(({ authReducer }) => authReducer)
  const {sessionId} = yield select(({ eventReducer }) => eventReducer)

  const baseEvent = ({
    datetime: new Date().toISOString(),
    analytics_session_id: sessionId,
  }) 
  const payload = {
    ...action.payload,
    ...baseEvent
  }
  // const user =  
  if(process.env.REACT_APP_SEND_EVENTS) {
    try {
      switch (action.type) {
        case EventActionTypes.VISIT_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/visit-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.SIGN_IN_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/sign-in-event',payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.MIDI_CONNECTION_SUCCESS_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/midi-connection-success-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.MIDI_CONNECTION_FAILURE_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/midi-connection-failure-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.LEVEL_PLAYING_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/level-playing-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.TUTORIAL_PAGE_VIEW_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/tutorial-page-view-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.TUTORIAL_SKIPPED_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/tutorial-skipped-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.MOBILE_BLOCKED_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/mobile-blocked-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.TUTORIAL_STARTED_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/tutorial-start-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.PHRASE_COMPLETED_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/phrase-completed-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.SAFARI_BLOCKED_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/safari-blocked-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        case EventActionTypes.USER_SYSTEM_DETAILS_EVENT_ACTION:
          console.log(`sending ${payload.event_type} event`)
          yield axios.post(eventsUrl + '/user-system-details-event', payload, {headers: {'Authorization': `Bearer ${jwtToken}`}});
          break;
        default:
          break;
     }  
    } catch (error) {
      awsRum?.recordError(error);
    }
  } else {
    console.log(`REACT_APP_SEND_EVENTS not set - not sending event ${ action.payload.event_type}. event = ${JSON.stringify(payload)}`)
  }
}

export default [
  takeEvery(EventActionTypes.VISIT_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.SIGN_IN_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.MIDI_CONNECTION_SUCCESS_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.MIDI_CONNECTION_FAILURE_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.LEVEL_PLAYING_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.TUTORIAL_PAGE_VIEW_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.TUTORIAL_SKIPPED_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.MOBILE_BLOCKED_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.TUTORIAL_STARTED_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.PHRASE_COMPLETED_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.SAFARI_BLOCKED_EVENT_ACTION, sendEventToBackend),
  takeEvery(EventActionTypes.USER_SYSTEM_DETAILS_EVENT_ACTION, sendEventToBackend),
  onStartup()
]
