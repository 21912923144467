import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Formik, Form } from "formik";
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import { filterFalsy } from 'Utils';
import { useAuthenticationContext } from 'Contexts/Authentication';
import { UpdateProfileData, ChangePasswordData } from 'Types'; 
import UpdateProfileForm, { updateProfileFormSchema} from 'Components/Forms/UpdateProfileForm';
import ChangePasswordForm, { changePasswordFormSchema } from 'Components/Forms/ChangePasswordForm';
import VerificationCode from 'Containers/SignIn/VerificationCode';
import {pick, has} from 'lodash'
import { useEffect } from 'react';
import { isEmail, isPhoneNumber } from 'Components/Forms/SignUpForm';
import { string } from 'postcss-selector-parser';

const Account = () => {
  const ATTRIBUTES = "ATTRIBUTES", VERIFICATION = "VERIFICATION";
  const [ authErrors, setAuthErrors ] = React.useState({
    name: "",
    emailOrPhone: ""
  })

  const [ accountSuccessMessage, setAccountSuccessMessage ] = React.useState<String | undefined>()
  const [ changePassSuccessMessage, setChangePassSuccessMessage ] = React.useState<String | undefined>()
  const resetAuthErrors = () => {
    setAuthErrors({
      name: "",
      emailOrPhone: "",
    })
  }

  const { 
    setPendingEmailOrPhone,
    updateUserAttributes, 
    user, 
    verifyCurrentUserAttributeSubmit, 
    verifiedContact, 
    verifyUserAttribute ,
    changePassword,
    pendingEmailOrPhone,
    refreshUser
  } = useAuthenticationContext();
  const isGoogleUser = user.username.startsWith("google")
  const [ attrComponent, setAttrComponent ] = React.useState(ATTRIBUTES);
  const [ showUnverifiedMessage, setShowUnverifiedMessage] = React.useState<Boolean>(false);
  
  useEffect(() =>{
    // declare the data fetching function
    const getverifiedContact = async () => {
      return await verifiedContact()
    }
    getverifiedContact()
    .then(({unverified, verified}) => {
      if(!isGoogleUser && (unverified as any)?.email) {
        setShowUnverifiedMessage(true);
      }
      if(!isGoogleUser && (unverified as any)?.phone_number) {
        setShowUnverifiedMessage(true);
      }
    })
    .catch(console.error)
  },[])
  return (
      <Box sx={{height: 'calc(100vh - 200px)'}}>
      <Box sx={{height: 'calc(100vh - 156px)', 
      width: '100%', overflow: 'scroll', paddingTop: '20px'}}>
      {/* </Box> */}
      <Box sx={{
        marginLeft: '50px',
        width: '600px',
        paddingTop: '20px',
        backgroundColor: 'black',
        borderRadius: '5px',
        }}>
      <Box sx={{
        color: 'white',
        textAlign: 'left',
        marginLeft: '24px',
        marginRight: '24px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Lato',
      }}>
          {
            attrComponent === ATTRIBUTES && 
            <>
              <Box sx={{
                marginBottom: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '26px',
                letterSpacing: '0.01em',
                color: '#FFFFFF',
                }}>
              Update Profile
              </Box>
            
              <Formik
                initialValues={{
                  "name": "",
                  "emailOrPhone": "",
                  "unknown": ""
                }}
                validate={(values: UpdateProfileData) => {
                  return ValidateForm(updateProfileFormSchema,values)}
                }
                onSubmit={async (values: UpdateProfileData) => {
                  const filteredValues: Partial<UpdateProfileData> = filterFalsy(pick(values, ['name', 'emailOrPhone']));
                  let finalValues: {name?: string, email?: string, phone_number?: string} = {};
                  if(has(filteredValues, 'emailOrPhone') && isPhoneNumber(filteredValues['emailOrPhone'])) {
                    const strippedNumber = "+1" + filteredValues['emailOrPhone']?.replace(/[^0-9.]/g, '');
                    finalValues['phone_number'] = strippedNumber;
                  }
                  if(has(filteredValues, 'emailOrPhone') && isEmail(filteredValues['emailOrPhone'])) {
                    finalValues['phone_number'] = filteredValues['emailOrPhone'];
                  }
                  if(has(filteredValues, 'name')) {
                    finalValues.name = filteredValues.name;
                  }
                  return updateUserAttributes(
                    finalValues
                  )
                  .then(result => {
                    if(values?.emailOrPhone &&
                      (user?.attributes?.email !== values?.emailOrPhone &&
                      user?.attributes?.phone_number !== values?.emailOrPhone)
                    ) {
                      setPendingEmailOrPhone(values.emailOrPhone);
                      setAttrComponent(VERIFICATION)
                    } else {
                      setAccountSuccessMessage("profile successfully updated")
                    }
                    // setResetEmail(values.email)
                    // goToReset()
                  })
                  .catch(err => { 
                    console.error(err.code)
                    if(err.code === "UserNotConfirmedException") {
                    //   setPending()
                    //   setPendingEmail(values.email);
                    } else {
                    const handledAuthErrors = handleAuthErrors(err);
                    setAuthErrors(handledAuthErrors)
                    if(Object.keys(handledAuthErrors).includes('unknown')) {
                      throw err;
                    }
                    }
                  });
                }}
                validateOnChange={false}
              >
                {formikProps => (
                <Form
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  resetAuthErrors();
                  formikProps.handleSubmit(e);
                  }}
                >
                  <Box sx={{width: '350px'}}>
                  <UpdateProfileForm 
                    successMessage={accountSuccessMessage}
                    placeholders={{
                      name: user?.attributes?.name || undefined,
                      emailOrPhone: user?.attributes?.email || user?.attributes?.phone_number.slice(2) || undefined,
                      unknown: ""
                    }}
                    {...formikProps}
                    errors={{...authErrors,...formikProps.errors}}
                  />
                  </Box>
                </Form>
                )}
              </Formik>
            </>
          }
          {
            showUnverifiedMessage &&
            <Button 
              variant="text" 
              onClick={async () => {
                if(isPhoneNumber(pendingEmailOrPhone)) {
                  await verifyUserAttribute("phone_number")
                }
                if(isEmail(pendingEmailOrPhone)) {
                  await verifyUserAttribute("email")
                }
                setShowUnverifiedMessage(false)
                setPendingEmailOrPhone(undefined)
                setAttrComponent(VERIFICATION);
              }} 
              sx={{
                textAlign: 'center',
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': {
                  textDecoration: 'underline'
                },
                paddingBottom: "20px"
              }}
            >
              {isPhoneNumber(pendingEmailOrPhone) && 
              "Your Phone number is unverified. Please click here to recieve a verification code"}
              {isEmail(pendingEmailOrPhone) && 
              "Your email is unverified. Please click here to receive a confirmation link"}
            </Button>
          }
          {
            attrComponent === VERIFICATION &&
            <VerificationCode 
              additionalCompletionFunction={async confirmationCode => {
                  if(isPhoneNumber(pendingEmailOrPhone)) {
                    await verifyCurrentUserAttributeSubmit("phone_number", confirmationCode);
                  }
                  if(isEmail(pendingEmailOrPhone)) {
                    await verifyCurrentUserAttributeSubmit("email", confirmationCode);
                  }
                  setAttrComponent(ATTRIBUTES);
                  setAccountSuccessMessage("verified.");
                  refreshUser();
              }
              }
            />
          }
      </Box>
      </Box>
      {
        !isGoogleUser &&
        <Box sx={{
          marginLeft: '50px',
          width: '600px',
          marginTop: '20px', 
          backgroundColor: 'black',
          borderRadius: '5px',
          }}>
          <Box sx={{
            color: 'white',
            textAlign: 'left',
            marginLeft: '24px',
            marginRight: '24px',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '40px',
          }}>
              
              <Box sx={{
                paddingTop: '20px',
                marginBottom: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '26px',
                letterSpacing: '0.01em',
                color: '#FFFFFF',
                fontFamily: 'Lato',
                }}>
              Change Password
              </Box>
          
              <Formik
                initialValues={{
                  "currentPassword": "",
                  "newPassword": "",
                  "repeatNewPassword": "",
                  "unknown": ""
                }}
                validate={(values: ChangePasswordData) => {
                  if(values.newPassword !== values.repeatNewPassword) {
                    return {
                      newPassword: "Passwords must match",
                      repeatNewPassword: "Passwords must match",
                    }
                    }
                  return ValidateForm(changePasswordFormSchema,values)}
                }
                onSubmit={async (values) => {
                  return changePassword(
                    values.currentPassword, values.newPassword
                  )
                  .then(() => {
                    setChangePassSuccessMessage("Password successfully updated")
                  })
                  .catch(err => { 
                    console.error(err.code)
                    if(err.code === "UserNotConfirmedException") {
                    //   setPending()
                    //   setPendingEmail(values.email);
                    } else {
                    setAuthErrors(handleAuthErrors(err))
                    }
                  });
                }}
                validateOnChange={false}
                >
                  {formikProps => ( 
                  <Form
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                      e.preventDefault();
                      resetAuthErrors();
                      formikProps.handleSubmit(e);
                    }}
                  >
                    <Box sx={{width: '350px'}}>
                    <ChangePasswordForm  
                      successMessage={changePassSuccessMessage}           
                      {...formikProps}
                      errors={{...authErrors,...formikProps.errors}}
                    />
                    </Box>
                  </Form>
                  )}
                </Formik>
            </Box>
        </Box>
      }
      </Box>
      </Box>
  )
}

export default Account
