import React from 'react';
import { Box, Link } from '@mui/material';
import WelcomeButton from './WelcomeButton'
import WelcomeLink from './WelcomeLink'
import { useNavigate } from "react-router-dom";
import KeyboardConnectMidi from '../../assets/images/KeyboardConnectMidi.png'
import IntroFlowBack from 'Components/IntroFlowBack';
import Logo from 'Components/Logo';
import './trial_welcome.css'
import './welcome.css'
import { useMidiContext } from 'Contexts/MidiContext';
import { MainAppReducer } from 'Types';
import { useDispatch, useSelector, } from 'react-redux'
import * as eventActions from 'Actions/events';

const ConnectMidi = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const previousLocation = sessionStorage.getItem('previousLocation') || ''
  const { initializeMidiInput } = useMidiContext();
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const userData = data.userData
  // To determine nav back & later option
  let inWelcomeFlow = !userData?.subscription_status

  React.useEffect(()=>{
    dispatch(eventActions.midiConnectionFailureEventAction())
  }, [])

  const navConnectMidi = () => {
    if (inWelcomeFlow || previousLocation == '/welcome') { // for trial welcome flow!
      navigate(`/trial-welcome`)
    } else if(previousLocation == '/roadmap') {
      // doesn't look like the unmount hook is firing fast enough on the tutorial page so,
      // if the last page is roadmap, go to tutorial. This only makes sense if the roadmap page itself
      // is not a midi connect redirect page.
      navigate('/roadmap')
    } else if(previousLocation === '') {
      navigate('/roadmap')
    } else if(previousLocation == '/connect-midi') {
      navigate('/lesson')
    } else {
      navigate(previousLocation)
    }
  }
  const handleBack = () => {
    if (inWelcomeFlow || previousLocation == '/welcome') {
      navigate(`/welcome`)
    } else if(previousLocation == '/tutorial') {
      navigate(`/roadmap`)
    } else if (previousLocation == '/lesson' || previousLocation == '/introduction' || previousLocation == '/connect-midi') {
      navigate('/tutorial')
    } else { // otherwise just navigate back (not coming from redirect)
      navigate(-1);
    }
  }

  const onSubmit = async () => {
    try {
      await initializeMidiInput()
    } catch(err) {
      console.error('error connecting to midi:', err)
    }
    navConnectMidi()
  }
  const onLater = () => {
    navigate('/trial-welcome')
  }
  return (
    <Box className="page-div-styles">
    <Box className="filter-div-styles">
    <Box className="sign-in-div-styles">
      <IntroFlowBack handleBack={handleBack}/>
      <Logo/>
      
      <Box className="desc-container welcome-container">
        <h3 className="title-welcome" style={{marginTop: '50px'}}>Connect your MIDI keyboard</h3>
        <p className="p-connect-midi">
          To use the beta version of MuseFlow, you will need a MIDI keyboard.
          <br/>
          Once connected, hit NEXT.  
          <br/><br/>
          <Link href="https://museflow.ai/faq" target="_blank" className="link">Need Help?</Link>
        </p>
      </Box>
      <Box sx={{height: '160px'}}>
        <img style={{maxHeight: '100%'}} src={KeyboardConnectMidi}/>
      </Box>
      <Box className="connect-button-container">
        <WelcomeButton onClick={onSubmit}>
          NEXT
        </WelcomeButton>
        {inWelcomeFlow &&
          <WelcomeLink onClick={onLater}>
            Later
          </WelcomeLink>
        }
        </Box>
      </Box>
    </Box>
    </Box>
  )
}

export default ConnectMidi;

