import { AugmentedCognitoUser } from "Types";




export interface AuthReducer {
  authReducer: AuthState,
}
export interface AuthState {
  jwtToken: string,
  idToken: string,
  user: AugmentedCognitoUser | null
}

export enum authActionTypes {
  SET_TOKEN = "SET_TOKEN",
  SET_ID_TOKEN = "SET_ID_TOKEN"
}

export interface SetTokenPayload {
  token: string
}

export interface SetIdTokenPayload {
  idToken: string
}

export interface SetToken {
  type: typeof authActionTypes.SET_TOKEN;
  payload: SetTokenPayload
}

export interface SetIdToken {
  type: typeof authActionTypes.SET_ID_TOKEN;
  payload: SetIdTokenPayload
}

export type AuthActions =
  | SetToken
  | SetIdToken 