import { GraphicalNote } from 'opensheetmusicdisplay';
import { OpenSheetMusicDisplay as OSMD, VexFlowGraphicalNote } from 'opensheetmusicdisplay';
import MusicXML from 'Models/MusicXML';
import { PrerenderedGraphics } from 'Types';

const updateColorOfAllPaths = (node: HTMLElement, color: string) => {
  for(let i = 0; i < node.children.length; i++) {
    let htmlNode = (node.children[i] as HTMLElement);
    let path = htmlNode.nodeName.toLowerCase() === "path" ? htmlNode : (htmlNode.firstChild as HTMLElement)
    if (path && path.style) {
      path.style.color = color
      path.style.fill = color
      path.style.stroke = color
    }
  }
}

const updateNoteColor = (note: PrerenderedGraphics, color: string) => {
  const vgNote = (note as unknown as {staveNote: { id: string }});
  // let staveNote = vgNote.getSVGGElement()
  let staveNote = document.getElementById(vgNote.staveNote.id)
  staveNote?.childNodes.forEach(n => {
    let vfNnote = n as HTMLElement
    updateColorOfAllPaths(vfNnote, color)
  })

  if (staveNote?.parentNode) {
    updateColorOfAllPaths(staveNote?.parentNode.lastChild as HTMLElement, color)
  }
}

export const correctColor = '#469f6a'
export const mistimedColor = '#aebb1a'
export const wrongColor = '#eb3459'

export function makeNoteCorrect(notes: PrerenderedGraphics[]) {
  notes.forEach(n => updateNoteColor(n, correctColor))
}

export function makeNoteWrong(notes: PrerenderedGraphics[]) {
  notes.forEach(n => updateNoteColor(n, wrongColor))
}

export function makeNoteMistimed(notes: PrerenderedGraphics[]) {
  notes.forEach(n => updateNoteColor(n, mistimedColor))
}

export function noteIsRed(note: GraphicalNote) {
  return note.sourceNote.NoteheadColor === '#FF0000';
}

export function noteIsGreen(note: GraphicalNote) {
  return note.sourceNote.NoteheadColor === '#00FF00';
}

export const createOSMD = (divRef: React.RefObject<HTMLDivElement>, drawTimeSignatures = true) => {
  const osmd = new OSMD(divRef.current as HTMLDivElement,{
    drawTitle: false,
    drawTimeSignatures,
    drawMeasureNumbers: false,
    drawFingerings: true,
    drawCredits: false,
    drawPartNames: false,
    autoResize: false,
    stretchLastSystemLine: true,
    disableCursor: false,
    drawingParameters: 'compacttight'
  })
  osmd.EngravingRules.BetweenStaffDistance = 2
  osmd.EngravingRules.BetweenStaffLinesDistance = 2
  return osmd
}

export const loadNewMusic = (musicXML: MusicXML, osmd:OSMD) => {
  osmd.load(musicXML.xml);
  osmd.cursor.reset();
  osmd.render();
}
