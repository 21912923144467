import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { SignUpData } from "Types";
import { CustomTextField, CustomButton, CustomCheckbox } from "Components/StyledComponents"
import { FormikProps } from "formik";

import * as yup from "yup";
import { MUSEFLOW_OFF_WHITE_1 } from "Components/Colors";

export type SignUpFormProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & FormikProps<SignUpData>;

export const isPhoneNumber = (value:string|undefined) => {
  const phoneSchema = yup.string().matches(
    /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    'Invalid phone number'
  );
  return phoneSchema.isValidSync(value || "");
}

export const isPhoneNumberWithoutCountryCode = (value:string|undefined) => {
  const phoneSchema = yup.string().matches(
    /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    'Invalid phone number'
  );
  return phoneSchema.isValidSync(value || "");
}

export const isEmail = (value:String|undefined) => {
  const emailSchema = yup.string().email()
  return emailSchema.isValidSync(value || "");
}

export const validateEmailorPhone = (emailOrPhone:string | undefined) => {
  return isEmail(emailOrPhone) || isPhoneNumber(emailOrPhone)
}

export const signUpFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is a required field"),
  emailOrPhone: yup
    .string()
    .test("emailOrPhoneNumber", "Must be an email or a phone number that starts with a country code, i.e. +1 for U.S.",validateEmailorPhone)
    .required("Email or Phone is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters")
});

const SignUpForm: React.FC<SignUpFormProps> = (
  props: SignUpFormProps
) => {
  const { handleChange, values, errors } = props;

  const [passwordType, setPasswordType] = React.useState<"password"|"text">("password") 
  
  return (
    <Box className="signup-form-container">

      <CustomTextField
        id="name"
        name="name"
        type="text"
        onChange={handleChange}
        label="Full Name"
        value={values.name || ""}
        error={!!errors.name}
        helperText={errors.name}
        fullWidth
      />

      <CustomTextField
        onChange={handleChange}
        id="emailOrPhone"
        name="emailOrPhone"
        type="text"
        label="Email or Phone Number"
        value={values.emailOrPhone || ""}
        error={!!errors.emailOrPhone}
        helperText={errors.emailOrPhone}
        fullWidth
      />

      <CustomTextField
        hidden={false}
        onChange={handleChange}
        id="password"
        name="password"
        type={passwordType}
        label="Password"
        value={values.password || ""}
        error={!!errors.password}
        helperText={errors.password}
        fullWidth
      />

      <CustomCheckbox 
        id="showPassword"
        name="showPassword"
        label="Show password"
        value={false}
        checked={false}
        defaultChecked={false}
        sx={{
          margin: 0
        }}
        onChange={()=>{
          if(passwordType === 'password') {
            setPasswordType('text')
          } else if(passwordType === 'text') {
            setPasswordType('password')
          }
        }}
      />

      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <CustomButton
          variant="contained"
          type="submit"
        >
          CREATE ACCOUNT
        </CustomButton> 
      </Box>
      <Box>
        <Typography color="error" variant="h6">
          {errors.unknown}
        </Typography> 
      </Box>
    </Box>
  );
};


const textStyles = {
  fontFamily: 'Lato',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.16px',
}

export default SignUpForm;