import { AuthState, authActionTypes, AuthActions } from "Types/AuthTypes";
import immutable from 'immutable';


export const defaultAuthState: AuthState = {
  jwtToken: "",
  idToken: "",
  user: null
}

export const authReducer = (state = defaultAuthState, action: AuthActions) => {
  switch(action.type) {
    case authActionTypes.SET_TOKEN:
      return immutable.merge(
        state,
        {
          jwtToken: action.payload.token
        }
      )
    case authActionTypes.SET_ID_TOKEN:
      return immutable.merge(
        state,
        {
          idToken: action.payload.idToken
        }
      )
    default:
      return state
  }
} 