import { Box, IconButton } from '@mui/material';
import { useState, useEffect } from "react";
import { MainAppReducer } from 'Types';
import { Link } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import CloseIconGray from '../../assets/images/CloseIconGray.png';
import * as appActions from '../../Actions/app'
import { useAuthenticationContext } from 'Contexts/Authentication';
import { SURVEY_LINK } from '../../Utils/Constants'
import { AuthReducer } from 'Types/AuthTypes';

const surveyHiddenHeight = -40
const hoursWaitShowSurvey = 24 * 28 // 4 weeks

const Survey = () => {

  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const { userData } = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const [surveyPos, setSurveyPos] = useState(surveyHiddenHeight)
  const [showSurvey, setShowSurvey] = useState(false)

  const dispatch = useDispatch()
  const setSurveyShown = (stopSurvey: boolean) => {
    let dateSet = new Date();
    if (stopSurvey) { // set to a year away
      dateSet.setFullYear(dateSet.getFullYear() + 1);
    }
    dispatch(appActions.setLastShowSurvey({
      last_shown_survey: dateSet,
      authToken: auth.jwtToken
    }))
  }
  useEffect(() => {
    if (userData) {
      if (data.highestLevelCompleted >= 1) {
        if (userData.last_shown_survey ) {
          const now: Date = new Date()
          let diffInMilliseconds = now.getTime() - new Date(userData.last_shown_survey).getTime();
          let diffInHours = diffInMilliseconds / (1000 * 60 * 60);
          if (diffInHours >= hoursWaitShowSurvey) {
            setShowSurvey(true)
            setSurveyPos(0)
          }
        } else {
          setShowSurvey(true)
          setSurveyPos(0) // will show survey by sliding it into position
        }
      }
    }
  }, [userData && userData.last_shown_survey])

  if (!showSurvey) return (<></>)
  
  return (
    <Box sx={{
      position: 'fixed',
      backgroundColor: '#CA7C62',
      top: `${surveyPos}px`,
      right: '0px',
      left: '100px',
      transition: "top 1s",
      height: '40px',
      zIndex: 1000,
      display: 'flex', alignItems: 'center', justifyContent: 'space-between'
    }}>
      <Box sx={{
        marginLeft: '50px',
        color: '#414753',
        fontSize: '16px',
        letterSpacing: '0.15px',
      }}>How's your experience been? We'd love to hear from you! <Link 
        href={SURVEY_LINK}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          setSurveyPos(surveyHiddenHeight)
          setSurveyShown(true)
        }}
        sx={{
          textAlign: 'center' as 'center',
          cursor: 'pointer',
          color: '#414753',
          fontWeight: 'bold',
        }}>
          Take our survey.
        </Link>
      </Box>
      <Box sx={{marginRight: '16px'}}>
      <IconButton
        onClick={() => {
          setSurveyPos(surveyHiddenHeight)
          setSurveyShown(false)
        }}
      >
        <img style={{color: '#414753'}} src={CloseIconGray}/>
      </IconButton>
      </Box>
    </Box>
  )
}
export default Survey
