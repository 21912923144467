import React, { useCallback, useEffect } from 'react';
import { Box } from "@mui/system"
import CheckmarkSmall from '../../assets/images/CheckmarkSmall.png';
import CloseIcon from '../../assets/images/CloseIcon.png';
import { useNavigate } from 'react-router-dom';
import RoadmapTierComplete from '../../assets/images/RoadmapTierComplete.png'
import {  useDispatch, useSelector } from 'react-redux'
import * as appActions from 'Actions/app';
import { MainAppReducer, LevelStatus } from 'Types';
import { getLevelDataByLevelNumber } from 'Utils';
import { useAuthenticationContext } from "Contexts/Authentication";
import { CustomButton } from 'Components';
import { range } from 'lodash'
import { AuthReducer } from 'Types/AuthTypes';
const TierComplete = (props: any) => {
  const { user } = useAuthenticationContext();
  const dispatch = useDispatch();
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const levelData = data.levelData[data.levelSelect];
  const { showTierComplete, setShowTierComplete, setAccuracy, setShowAccuracy } = props
  const navigate = useNavigate(); 
  const levelComplete = data?.currentUserLevelData?.current_tier && data?.currentUserLevelData?.current_tier >= data.currentLevelTiers

  const updateTier = useCallback((tier: number)=>{
    dispatch(appActions.updateCurrentULP({
      authToken: auth.jwtToken,
      currentUserLevelProgress: {
        current_tier: tier// 3,
        // highest_tier_complete: 3,
        // status: LevelStatus.complete//"active"
      },
      user
    }))
  },[dispatch, auth.jwtToken])

  // useEffect(() => {
  // dispatch(appActions.updateCurrentULP({
  //   authToken: user?.signInUserSession?.accessToken?.jwtToken,
  //   currentUserLevelProgress: {
  //     current_tier: 1,
  //     highest_tier_complete: 0,
  //     // status: LevelStatus.complete//"active"
  //   }
  // }))
  // }, [])

  if (!showTierComplete) { return <></>}
  return (
    <Box sx={{
      position: 'fixed',
      width: '60%',
      height: '60%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -45%)',
      zIndex: 3,
      background: 'linear-gradient(229.67deg, #1D2F44 -6.17%, #071423 89.04%)',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', justifyContent: 'center'
    }}>
      
      <Box sx={{
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#469F6A',
        textTransform: 'uppercase',
      }}>{levelData?.name}</Box>
      <Box sx={{
        marginTop: '10px',
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '34px',
        lineHeight: '41px',
        color: 'white',
        letterSpacing: '0.01em',
      }}>{levelComplete ? "Level" : `Tier ${data?.currentUserLevelData?.current_tier} of ${data?.currentLevelTiers}`} Complete!</Box>
      <Completion/>
      <Box
        sx={{
          width:'500px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px 32px',
          gap: '8px',
          width: '160px',
          height: '28px',
          outlineColor: '#CA7C62',
          outlineStyle: 'solid',
          borderRadius: '40px',
          '&:hover': {
            cursor: 'pointer'
          },
        }}
        onClick={async () => {
          await setShowAccuracy(false)
          await setShowTierComplete(false)
          await dispatch(appActions.updatePhrasesTilNextTier(4))
          await setAccuracy(null)
          await dispatch(appActions.resetLesson())
        }}
        >
          <Box sx={{
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '19px',
            textAlign: 'center',
            letterSpacing: '2.5px',
            textTransform: 'uppercase',
            color: '#CA7C62',

          }}>
            Replay Tier
          </Box>

        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px 32px',
          gap: '8px',
          width: '160px',
          height: '28px',
          background: '#CA7C62',
          borderRadius: '40px',
          '&:hover': {
            cursor: 'pointer'
          },
        }}
        onClick={() => {
          if(!levelComplete && data?.currentUserLevelData?.current_tier) {
            updateTier(data?.currentUserLevelData?.current_tier + 1)
            setShowTierComplete(false)
          } else if(levelComplete) {
            dispatch(appActions.updatePhrasesTilNextTier(4))
            dispatch(appActions.setLevelSelect({
              levelSelect: data.levelSelect + 1
            }))
            dispatch(appActions.fetchTutorialData({
              authToken: auth.jwtToken,
            }))
            updateTier(1)
            setShowTierComplete(false)
            navigate('/tutorial')
          }
        }}
        >
          <Box sx={{
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '19px',
            textAlign: 'center',
            letterSpacing: '2.5px',
            textTransform: 'uppercase',
            color: '#050B13',
          }}>
            {levelComplete ? "Next Level" : "Next Tier"}
          </Box>

        </Box>
      </Box>
      <Box sx={{
        marginTop: '30px',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: '0.01em',
        color: '#CA7C62',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      onClick={() => {
        if(!levelComplete && data?.currentUserLevelData?.current_tier) {
          updateTier(data?.currentUserLevelData?.current_tier + 1)
        } else if(levelComplete) {
          dispatch(appActions.updatePhrasesTilNextTier(4))
        }
        setShowTierComplete(false)
        navigate('/roadmap')
      }}
      >
        Back to Level Roadmap
      </Box>

      <Box sx={{position: 'absolute', left: '0px', top:'0px', width: '100%', height: '100%', zIndex: -100}}>
        <img src={RoadmapTierComplete} style={{width: '100%', height: '100%'}}/>
      </Box>

    </Box>
  )

}
export default TierComplete

const Completion = () => {
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)

  return (

    <Box sx={{
      marginTop: '60px',
      marginBottom: '60px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '80%',
    }}>
      {
        range(1, data?.currentLevelTiers + 1).map((tier) => {
          const hasReachedTier = data?.currentUserLevelData?.current_tier && tier > data?.currentUserLevelData?.current_tier;
          return (
            <Box sx={{
              display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
            }}>
              {
                hasReachedTier ? (
                  <>
                    <Box sx={{
                      ...lineStyles,
                      backgroundColor: hasReachedTier ? '#31353F': '#6F87F1',
                    }}/>
                    <Box sx={{
                      ...circleStyles,
                      backgroundColor: hasReachedTier ? '#31353F': '#6F87F1',
                      display: 'flex', alignItems: 'center', justifyContent: 'center', 
                    }}>
                      <img  src={CheckmarkSmall}/> {/*   The checkmark must be black  */}
                    </Box>
                  </>
                ) : 
                (
                  <>
                    <Box sx={{
                      ...lineStyles,
                      backgroundColor: hasReachedTier ? '#31353F': '#6F87F1',
                      marginLeft: '0px',
                    }}/>
                    <Box sx={{
                    ...circleStyles,
                      backgroundColor: hasReachedTier ? '#31353F': '#6F87F1',
                      display: 'flex', alignItems: 'center', justifyContent: 'center', 
                      color: hasReachedTier ? '#6F87F1': '#31353F',
                    }}>
                      {tier}
                    </Box>
                  </>
                )
              }
            </Box>
            
          )
        })
    }
     
    </Box>
  )
}

const lineStyles = {
  height: '5px',
  // width: '200px',
  flexGrow: 1,
  borderRadius: '99px',
}

const circleStyles = {
  width: '26px',
  height: '26px',
  borderRadius: '50%',
}

