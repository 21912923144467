import { Box, Link } from '@mui/material';
import WelcomeButton from './WelcomeButton'
import Logo from 'Components/Logo';
import { useNavigate, useResolvedPath } from "react-router-dom";
import './trial_welcome.css'
import './welcome.css'
import { useSelector } from 'react-redux';
import { MainAppReducer } from 'Types';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import * as appActions from '../../Actions/app'
import { useAuthenticationContext } from 'Contexts/Authentication';
import { CustomCheckbox } from 'Components/StyledComponents';
import { AuthReducer } from 'Types/AuthTypes';

const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { user } = useAuthenticationContext();
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const [acceptMail, setAcceptMail] = useState(true);
  const handleChange = (e: any) => {
    setAcceptMail(e.target.checked)
  }

  const onSubmit = () => {
    if (acceptMail) {
      // add user to mailchimp!
      dispatch(appActions.updateMailchimpData({
        signUpData: {
          name: user.attributes.name, 
          email: user.attributes.email
        }, authToken: auth.jwtToken
      }))
    }
    navigate('/trial-welcome')
  }
  const userData = useSelector((state: MainAppReducer) => state.mainAppReducer.userData)
  
  useEffect(() => {
    // redirect user back to roadmap so they can't access trial again and do something weird
    if (userData && userData.subscription_status != null && userData.subscription_status != "") {
      navigate('/roadmap')
    }
  }, [userData])

  return (
    <Box className="page-div-styles">
    <Box className="filter-div-styles">
    <Box className="sign-in-div-styles">
      <Logo/>
      <Box className="desc-container welcome-container">
        <h3 className="title-welcome" style={{marginTop: '50px'}}>Welcome!</h3>
        <p className="p-welcome-body">
          Our vision is to build a next-generation piano education app that feels more like play and less like work. We believe that music is a journey of self-discovery, and we hope that our app will help you find joy in that journey.
          <br/><br/>
          To get started, you'll need a MIDI keyboard. If you don't already have one, we have some recommendations for ones to purchase <Link href="https://museflow.ai/faq" target="_blank" className="link">here</Link>. You’ll be guided through the setup process on the next screen.
          <br/><br/>
          After your keyboard is connected, you'll be taken to our Level Roadmap. If you’re a beginner, we recommend starting at Level 1. If you have some experience already, feel free to browse through our levels and choose one that feels most suited for you.
          <br/><br/>
          This beta is the first step towards our vision, and we’re thrilled to have you on board as we work to refine and improve our app. If you have any questions or need any assistance, don't hesitate to contact us. We're here to help you on your musical journey!
          <br/><br/>
          Happy playing,<br/>
          The MuseFlow Team
        </p>
      </Box>


        <Box>
        <CustomCheckbox 
          sx ={{
            color: '#CED0D4',
            textWrap :"nowrap",
            // ...textStyles
          }}
          id="acceptMail"
          name="acceptMail"
          label="Keep me in the loop for news and updates!"
          value={acceptMail}
          onChange={handleChange}
        />
        </Box>

      <WelcomeButton onClick={onSubmit}>
        LET’S FLOW
      </WelcomeButton>
      </Box>
    </Box>
    </Box>
  )
}

export default Welcome;

