
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { MainAppReducer } from 'Types';
import FullScreenIn from '../assets/images/FullScreenIn.svg'
import FullScreenOut from '../assets/images/FullScreenOut.svg'
import { iconContainerStyles1, textStyles, hoverBoxStyles } from "./RoadmapComponents/Menu";
import * as appActions from '../Actions/app'

const Fullscreen = (props: { show_label: boolean }) => {
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const dispatch = useDispatch()
  const { show_label } = props
  const isFullScreen = data.isFullScreen

  useEffect(() => {
    // This is needed so that if they hit excape to leave fullscreen, it will adjust
    const handleFullScreenChange = () => {
      const isNowFullScreen = !!document.fullscreenElement;
      dispatch(appActions.setFullScreen(isNowFullScreen))
    };
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      dispatch(appActions.setFullScreen(true))
      document.documentElement.requestFullscreen().catch((e) => {
        console.error(`Error attempting to enable full-screen mode: ${e.message} (${e.name})`);
      });
    } else {
      if (document.exitFullscreen) {
        dispatch(appActions.setFullScreen(false))
        document.exitFullscreen();
      }
    }
  }

  if (show_label) {
    return (
      <Box sx={{...iconContainerStyles1, ...hoverBoxStyles, display: 'flex', alignItems: 'center', position: 'fixed', bottom: '20px', left: '11px' }}>
        <img 
          style={{width: 38, opacity: 0.7}}
          src={isFullScreen ? FullScreenOut : FullScreenIn}
          onClick={toggleFullScreen}
        />
        <Box sx={{...textStyles, marginTop: '10px'}}>Full Screen</Box>
      </Box>
    )
  } else {
    return (
      <Box sx={{
        '&:hover': {
          cursor: 'pointer',
        }
      }}>
        <img 
          style={{width: 38, opacity: 0.7}}
          src={isFullScreen ? FullScreenOut : FullScreenIn}
          onClick={toggleFullScreen}
        />
      </Box>
    )
  }
}
export default Fullscreen
