import { styled } from '@mui/material/styles';
import { LessonPageProps } from '../../Types';
import Accuracy from '../../Components/Accuracy'
import Tempo from '../../Components/Tempo'
import { LinearProgress, Box, Switch, FormGroup, FormControlLabel, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import MainApp from '../../Containers/MainApp/MainApp'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LeftArrowIcon from '../../assets/images/LeftArrowIcon.png'
import InfoIcon from '../../assets/images/InfoIcon.png'
import Chevron1 from '../../assets/images/Chevron1.png'
import ChevronSingle from '../../assets/images/ChevronSingle.png'
import ChevronFilled from '../../assets/images/ChevronFilled.png'
import ChevronLastFilled from '../../assets/images/ChevronLastFilled.png'
import MinusIcon from '../../assets/images/MinusIcon.png'
import PlusIcon from '../../assets/images/PlusIcon.png'
import PlayIconLight from '../../assets/images/PlayIconLight.png';
import CheckmarkSmall from '../../assets/images/CheckmarkSmall.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux'
import * as appActions from '../../Actions/app'
import { MainAppReducer } from 'Types';
// import { Levels } from '../../Constants/LevelData'
import { useNavigate, useLocation } from "react-router-dom";
import { CustomSwitch } from 'Components/StyledComponents';
import { useState, useEffect, useRef, useCallback } from 'react';
import { BPM } from 'Utils/Constants'
import './Lesson.css'
import TierComplete from './TierComplete';
import UnitComplete from './UnitComplete';
import FooterContainer from './Footer';
import { isConstructorDeclaration } from 'typescript';
import { range } from 'lodash'
import { colors, circleContainerStyles, numberStyles, selectedStyles, inProgressContainerStyles, completeContainerStyles } from 'Components/RoadmapComponents/Path';
import { useAuthenticationContext } from 'Contexts/Authentication';
import {v4 as uuid} from 'uuid';
import { MUSEFLOW_ORANGE } from 'Components/Colors';
import { AuthReducer } from 'Types/AuthTypes';

const activationAccuracyValue = 95
const minTempoForChevronFlash = 72

const smallScreenBreakWidth = 1200
const smallScreenBreakHeight = 900

const LessonPage = (props: any) => {
    // const { midiProps } = props
    // const location = useLocation();
    const dispatch = useDispatch();

    const navigate = useNavigate(); 
    const {user} = useAuthenticationContext();
    const auth = useSelector((state: AuthReducer) => state.authReducer)
    const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
    const { userLevelData, hasShownIntroduction } = data
    let levelSelect = data.levelSelect
    const [showTierComplete, setShowTierComplete] = useState(false)
    const [showUnitComplete, setShowUnitComplete] = useState(false)
    const [allowChevrons, setAllowChevrons] = useState(false)

    const [ accuracyPercent, setAccuracyPercent ] = useState<number | null>(0);
    const [ showAccuracy, setShowAccuracy ] = useState(false);

    const [currentTier, setCurrentTier] = useState(data?.currentUserLevelData?.current_tier || 1)

    const [cursorIsVisible, setCursorIsVisible] = useState(true);

    useEffect(() => {
      setCurrentTier(data?.currentUserLevelData?.current_tier || 1)
    }, [data?.currentUserLevelData?.current_tier])

    const updateCurrentTier =  useCallback((tier: number) => {
      setCurrentTier(tier)
      dispatch(appActions.updateCurrentULP({
        authToken: auth.jwtToken,
        currentUserLevelProgress: {
          current_tier: tier
        },
        user
      }))
    }, [user])
    
    useEffect(() => {
      if(data.phrasesTilNextTier === 0) {
        if (levelSelect == 26 && data?.currentUserLevelData?.current_tier == data?.currentLevelTiers) {
          setShowUnitComplete(true);
        } else {
          setShowTierComplete(true);
        }
        dispatch(appActions.setLessonPlaying({lessonPlaying: !data.lessonPlaying}))
      }
    }, [data.phrasesTilNextTier])

    const handleKeyDown = (e: any) => {
      // could also potentially check that no text fields are focued, but fine for now
      if (e.keyCode === 32) { //32 is space bar
        e.preventDefault(); //prevent scrollown with spacebar
      }
    };

    useEffect(() => {
      // check this... what if user-data errors?
      if((!userLevelData.length || userLevelData[0].highest_tier_complete == 0) && !hasShownIntroduction) {
      // if (!hasShownIntroduction) {
        // dispatch(appActions.setShownIntroduction(true))
        // Still navigating 
        navigate('/introduction')
      } else {
        // The not ideal way of restarting webworker... actually refreshing
        // const hasReloaded = localStorage.getItem('hasReloaded');
        // if (!hasReloaded || hasReloaded == 'false') {
          // localStorage.setItem('hasReloaded', 'true');
          // window.location.reload();
        // }

        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }
    }, []);

    const setAccuracy = (accuracy: number) => {
        setAccuracyPercent(accuracy)
    }
    
    return (
        <Box sx = {{display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'white'}}>
            <Header currentTier={currentTier} updateCurrentTier={updateCurrentTier} showAccuracy={showAccuracy} accuracyPercent={accuracyPercent} levelSelect={levelSelect} phrasesTilNextTier={data.phrasesTilNextTier} allowChevrons={allowChevrons}/>
            <Box className="main-app-container-styles"
              sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
            <MainApp currentTier={currentTier} setCurrentTier={setCurrentTier} updateCurrentTier={updateCurrentTier} showKeyboard={false} setAccuracy={setAccuracy} setShowAccuracy={setShowAccuracy} setAllowChevrons={setAllowChevrons} cursorIsVisible={cursorIsVisible}
            // midiProps={midiProps}
            />
            </Box>
            <TierComplete showTierComplete={showTierComplete} setShowTierComplete={setShowTierComplete} setAccuracy={setAccuracy} setShowAccuracy={(val: boolean) => { setShowAccuracy(val)}}/>
            <UnitComplete showUnitComplete={showUnitComplete} setShowUnitComplete={setShowUnitComplete} setAccuracy={setAccuracy} setShowAccuracy={setShowAccuracy}/>
            <FooterContainer cursorIsVisible={cursorIsVisible} setCursorIsVisible={setCursorIsVisible}/>
        </Box>
    )
}

const accuracyColors = [
  '#00C2FF', //neutral
  '#40E16D', // green
  '#EBC334', // yellow
  '#ff7d6f',
  // 'rgba(200, 128, 114, 1.0)',// '#EB3459', // red
]

interface HeaderProps {
  levelSelect: number;
  accuracyPercent: number | null;
  showAccuracy: boolean;
  phrasesTilNextTier: number;
  allowChevrons: boolean;
  updateCurrentTier: any;
  currentTier: number;
}

const Header = (props: HeaderProps) => {
    const { 
      levelSelect, 
      accuracyPercent, 
      showAccuracy, 
      phrasesTilNextTier,
      allowChevrons,
      updateCurrentTier,
      currentTier,
    } = props

    // const accuracyPercent = 0 // test value; comment out accuracyPercent from props above
    // const phrasesTilNextTier = 3 // test value; comment out phrasesTilNextTier from props above

    const [tempo, setTempo] = useState(BPM)

    const [isSmallScreen, setIsSmallScreen] = useState(false)//useState(window.innerWidth <= smallScreenBreakWidth || window.innerHeight <= smallScreenBreakHeight);
    useEffect(() => {
      const handleResize = () => { setIsSmallScreen(false)}//window.innerWidth <= smallScreenBreakWidth || window.innerHeight <= smallScreenBreakHeight); }
      window.addEventListener('resize', handleResize);
      return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
    const levelData = data.levelData
    const levelName = levelData[levelSelect].name
    const levelNumber = levelData[levelSelect].level_number
    const navigate = useNavigate();

    const chevronPulseStyles = {
      height: '100%',
      animation: (allowChevrons && accuracyPercent && accuracyPercent >= activationAccuracyValue) ? `pulse ${60/Math.min(tempo, minTempoForChevronFlash)}s infinite linear` : '',
    }
    const chevronContainerStyles = {
      position: 'absolute',
      height:'100%'
    }

    const positionData = [
      { til: 4, filledSrc: ChevronFilled, src: Chevron1 },
      { til: 3, filledSrc: ChevronFilled, src: Chevron1 },
      { til: 2, filledSrc: ChevronFilled, src: Chevron1 },
      { til: 1, filledSrc: ChevronLastFilled, src: ChevronSingle },
    ];

    let accuracyColor
    if (accuracyPercent == 0) {
      accuracyColor = accuracyColors[0]
    } else if (accuracyPercent && accuracyPercent >= 95) {
      accuracyColor = accuracyColors[1]
    } else if (accuracyPercent && accuracyPercent >= 75) {
      accuracyColor = accuracyColors[2]
    } else {
      accuracyColor = accuracyColors[3]
    }

    const [infoAnchorEl, setInfoAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(infoAnchorEl);
    const handleInfoClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setInfoAnchorEl(event.currentTarget);
    };
    const handleInfoClose = () => {
      setInfoAnchorEl(null);
    };

    return (
        <Box className="lesson-container-styles">
            <Box sx={{
                // background: 'linear-gradient(90deg, black, #1D2F44)', 
                background: 'linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)',
                width:'100%', display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'space-between'
                }}>
                  <Box sx={{position: 'relative', marginLeft: '80px', display:'flex', flexDirection:'column',alignItems:'center'}}>
                    <Box sx={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                    <Box 
                      sx={backcontainerStyles} 
                      onClick={() => navigate('/roadmap')}
                    >
                      <img style={{height: '100%'}} src={LeftArrowIcon}/>
                    </Box>
                    <Box className="header-text-styles">Level {levelNumber}: {levelName}</Box>
                      <Box 
                        sx={{...backcontainerStyles, marginLeft: '10px',
                        border: '1px solid rgba(202, 124, 98, .8)', borderRadius: '7px', padding: '3px 8px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px',
                        '&:hover': {cursor:'pointer'}}} 
                        onClick={handleInfoClick}
                        >
                          <img style={{height: '18px',}}src={InfoIcon}/>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                            <path d="M6.53151 4.97725L7.32365 5.74557L8.14993 4.96948L12.7227 0.44849L13.8975 1.62326L7.34521 8.17551L0.792969 1.62326L1.96774 0.44849L6.53151 4.97725Z" fill="#CA7C62"/>
                          </svg>
                      </Box>

                      <Menu
                        id="tutorial-info-menu"
                        anchorEl={infoAnchorEl}
                        open={open}
                        onClose={handleInfoClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                          sx:{
                            backgroundColor: 'black'
                          }
                        }}
                        sx={{
                          '.MuiListItemText-root': {
                            color: '#fff',
                            fontWeight: 'bold'
                          },
                          'bgcolor.MuiList-root-MuiMenu-list': {
                            backgroundColor: 'black',
                            color: '#fff'
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleInfoClose()
                            navigate('/tutorial')
                          }}>
                            <ListItemIcon
                              sx={{svg: {fill: MUSEFLOW_ORANGE}}}
                            >
                              <AutorenewIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText>Level Tutorial</ListItemText>
                        </MenuItem>
                        <MenuItem 
                          onClick={() => {
                            handleInfoClose()
                            navigate('/introduction')
                          }}
                        >
                          <ListItemIcon
                            sx={{svg: {fill: MUSEFLOW_ORANGE}}}
                          >
                            <AutorenewIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText>Game Tutorial</ListItemText>
                        </MenuItem>
                      </Menu>
                    </Box>
                    <Box sx={{
                      position: 'absolute', top: '44px', display: 'flex', justifyContent: 'space-between', 
                      width: (data?.currentLevelTiers > 1) ? '370px' : '230px',
                      marginRight: '20px'}}>
                    {
                      range(1, data?.currentLevelTiers + 1).map((tier) => {
                        const hasReachedTier = data?.currentUserLevelData?.highest_tier_complete && data?.currentUserLevelData?.highest_tier_complete >= tier
                        const isUnlocked = (tier == 1) || (data?.currentUserLevelData?.highest_tier_complete && tier == data?.currentUserLevelData?.highest_tier_complete +1)
                        let chosenStyles = {}
                        let bgColor = colors.background.incomplete.backgroundColor
                        if (hasReachedTier) {
                          chosenStyles = completeContainerStyles
                          bgColor = colors.background.complete.backgroundColor
                        } else if (isUnlocked) {
                          chosenStyles = {...inProgressContainerStyles, border: '3px solid #28856F'}
                          bgColor = '#ADB1B7'//colors.background.inProgress
                        }
                        return (
                            <Box 
                            key={uuid()}
                            sx={{
                              borderRadius: '99px',
                              backgroundColor: bgColor,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexGrow: 1, height: '14px', position: 'relative',
                              marginRight: '5px', marginLeft: '5px',
                              '&:hover': {
                                cursor: (hasReachedTier || isUnlocked) ? 'pointer' : '',
                                transition: (tier == currentTier || hasReachedTier || isUnlocked) ? '' : '0.3s',
                                boxShadow: ((tier == currentTier) || (tier != currentTier && !hasReachedTier && !isUnlocked)) ? '' : '0px 0px 5px 5px rgb(70, 159, 106,.5)'
                              },

                            }} 
                              onClick={() => {
                                // Weird bug that makes next tier chevrons full if clicked during tier complete dialogue
                                // so just make them not doing anything if phrases til next tier is zero
                                if ((hasReachedTier || isUnlocked) && !(data.phrasesTilNextTier === 0)) {
                                  updateCurrentTier(tier)
                                }
                              }}
                            >
                              { (tier == currentTier) && <Box sx={{
                                borderRadius: '10px', backgroundColor: 'rgba(70, 159, 106, 0.2)',
                                border: '2px solid rgba(70, 159, 106, .8)',
                                position: 'absolute', width: 'calc(100% + 4px)',
                                height: '18px',  zIndex: 10,
                              }}/>}
                            </Box>
                        )
                      })
                  }
                    </Box>
                  </Box>
                
                <TempoContainer tempo={tempo} setTempo={setTempo} isSmallScreen={isSmallScreen}/>
            </Box>
            
            <Box className="accuracy-container-styles">
                <Box sx={{height: '100%', display:'flex',flexDirection:'row', position: 'relative'}}>
                    <Box className="accuracy-container-styles2">
                        <Box className="accuracy-title-text-styles">Accuracy</Box>
                        {
                            showAccuracy && <Box className="accuracy-text-styles" sx={{color: accuracyColor}}>{accuracyPercent ? `${accuracyPercent?.toFixed(1)}%` : '--'}</Box>
                        }
                        {
                            !showAccuracy &&<Box className="accuracy-text-styles">--</Box>
                        }
                    </Box>
                    <Box className="first-chevron-styles"/>
                    {positionData.map((v, idx) => {
                      const curChevronDiff = v.til - phrasesTilNextTier;
                      const imgStyle = curChevronDiff === 0 ? chevronPulseStyles : { height: '100%' };
                      let imgSrc;
                      if (curChevronDiff > 0) {
                        imgSrc = v.filledSrc;
                      } else if (curChevronDiff === 0) {
                        imgSrc = (allowChevrons && accuracyPercent && accuracyPercent >= activationAccuracyValue) ? v.filledSrc : v.src;
                      } else {
                        imgSrc = v.src;
                      }
                      return (
                        <Box key={uuid()} className={`left-position-${idx+1}`} sx={{ ...chevronContainerStyles }}>
                          <img style={imgStyle} src={imgSrc} />
                        </Box>
                      );
                    })}

                </Box>
            </Box>
        </Box>
  )
}

const lineStyles = {
  height: '5px',
  // width: '200px',
  flexGrow: 1,
  borderRadius: '99px',
}

const circleStyles = {
  width: '26px',
  height: '26px',
  borderRadius: '50%',
}

const backcontainerStyles = {
    marginRight: '18px', 
    height:'25px',
    '&:hover': {
        background: 'rgba(222,144,118,.5)',
        cursor: 'pointer',
        transition: '0.3s',
        borderRadius:'50%',
        boxShadow: '0px 0px 5px 5px rgb(222,144,118,.5)',
      },
}

const TempoContainer = (props: any) => {
    const {
      tempo, setTempo, isSmallScreen
    } = props
    const [amount, setAmount] = useState(0)
    const [message, setMessage] = useState('')
    const inputRef: any = useRef(null);

    const dispatch = useDispatch()
    const tempoLowerLimit = 20;
    const tempoUpperLimit = 300;
    const goalTempo = 72;

    const setDispatchTempo = (tempo: number) => {
      dispatch(appActions.setTempo({tempo: tempo}))
    }

    useEffect(() => {
        setDispatchTempo(tempo) 
        if (inputRef.current) {
          inputRef.current.value = tempo
          inputRef.current.style.width = `${String(tempo).length}ch`;
        }
        if (tempo < goalTempo) {
          setAmount(goalTempo - tempo)
          setMessage("below")
        } else {// if (tempo == goalTempo) {
          setAmount(0)
          setMessage("at goal")
        }

    }, [tempo])

    const setTempoBlur = (val: string) => {
      let tempoVal = Math.round(parseFloat(val))
      if (isNaN(tempoVal) || tempoVal < tempoLowerLimit) tempoVal = tempoLowerLimit
      if (tempoVal > tempoUpperLimit) tempoVal = tempoUpperLimit
      setTempo(tempoVal)
    }

    return (
        <Box className="tempo-container-styles">
          { (!isSmallScreen) &&
            <Box sx={{
                textAlign: 'left',
                //fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '1px',
                textTransform: 'uppercase',
                color: '#ECECEC',
                marginBottom: '10px',
            }}>Tempo</Box>
          }
          <Box className="tempo-row-styles">
            { (isSmallScreen) &&
              <Box sx={{
                textAlign: 'left',
                //fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '1px',
                textTransform: 'uppercase',
                color: '#ECECEC',
                // marginBottom: '10px',
                marginRight: '10px',
              }}>Tempo</Box>
            }

            <Box sx={{
                background: '#ADB1B7',
                //border: '2px solid #ADB1B7',
                borderRadius: '4px',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                alignItems:'center',
                justifyContent: 'space-between'
            }}>
                <Box className="tempo-dec-container-styles" onClick={() => setTempo(tempo > tempoLowerLimit ? tempo-1 : tempoLowerLimit)}>
                    <img style={{width: '40%'}} src={MinusIcon}/>
                    </Box>
                <Box sx={{
                    marginLeft:'10px',
                    marginRight:'10px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.01em',
                    color: '#414753',
                    minWidth: '80px',
                }}>
                  {/* {tempo}  */}
                <input type="text"
                  ref={inputRef}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.01em',
                    color: '#414753',
                    background: '#ADB1B7',
                    border: 'none',
                    marginRight: '3px',
                }}
                  onChange={(e) => {
                    if (inputRef.current) {
                      inputRef.current.style.width = `${e.target.value.length}ch`;
                    }
                  }}
                  onBlur={(e) => {
                    setTempoBlur(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (inputRef.current) {
                        inputRef.current.blur();
                        setTempoBlur(e.currentTarget.value)
                      }
                    }
                  }}
                  />
                bpm</Box>
                <Box className="tempo-inc-container-styles" onClick={() => setTempo(tempo < tempoUpperLimit ? tempo+1 : tempoUpperLimit)}>
                    <img style={{width: '40%'}} src={PlusIcon}/>
                    </Box>
            </Box>

            </Box>
            <Box sx={{
              marginLeft: isSmallScreen ? '70px':'',
              display:'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px'}}>
                <Box sx={{backgroundColor: amount != 0 ? '#EB3459':'#40E16D', opacity: amount != 0 ? '1.0':'0.5', width:'10px', height:'10px',borderRadius:'50%'}}></Box>
                <Box sx={{
                    marginLeft:'8px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '19px',
                    letterSpacing: '0.01em',
                    color: '#ECECEC'
                }}>
                  { amount != 0 &&
                    <>{amount} bpm </>
                  }
                  {message} tempo
                </Box>
            </Box>

        {/* </Box> */}
        </Box>
    )
}

export default LessonPage