
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { mainAppReducer } from './mainAppReducer'
import { authReducer } from './authReducer'
import { History } from 'history'
import { eventReducer } from './eventReducer'

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  mainAppReducer: mainAppReducer,
  authReducer: authReducer,
  eventReducer: eventReducer,
})

export default createRootReducer;

export type RootState = ReturnType<typeof createRootReducer>
export type AppDispatch = typeof createRootReducer
