import { IconButton, Box } from '@mui/material';
import { useAuthenticationContext } from 'Contexts/Authentication';
import LogoutIcon from '@mui/icons-material/Logout';

const LogOutButton = () => {
  const { signOut } = useAuthenticationContext();
  return (
    <Box>
      <IconButton
        onClick={ () => {
          console.log("signing out")
          signOut()
        }}
      >
        <LogoutIcon
          sx={{
            color: 'rgb(255,255,255,0.8)',
            "&:hover": {
              color: "rgb(255,255,255,1.0)"
            },
            fontSize: '26px',
            transform: 'scaleX(-1)',
          }}
        />
      </IconButton>
    </Box>
  )
}

export default LogOutButton
