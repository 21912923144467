import Events from './Events';
import AppData from './AppData';
import { all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    ...Events,
    ...AppData
  ])
}
