import { useState, FC, useEffect } from 'react'
import { useAuthenticationContext } from 'Contexts/Authentication';
import { Box } from '@mui/material';
import { CustomCircularProgress } from 'Components/StyledComponents';
import { useNavigate } from "react-router-dom";
import ColoredLogo from '../../assets/images/ColoredLogo.png';
import pianoBackground from '../../assets/images/pianoBackground.png';
import { useSelector, useDispatch } from 'react-redux'
import { MainAppReducer } from 'Types';
import { isPhoneNumber } from 'Components/Forms/SignUpForm';
import { confirmSignUp } from 'Utils/Amplify';
import {useMediaQuery} from '@mui/material';
import Logo from 'Components/Logo';
import SignIn from './SignIn'
import '../TrialWelcome/welcome.css'
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import VerificationCode from './VerificationCode';
import { useLocation } from 'react-router-dom';
import { useAWSRumContext } from 'Contexts/AWSRum';
import { MUSEFLOW_OFF_WHITE_1 } from 'Components/Colors';
import { AuthReducer } from 'Types/AuthTypes';

const SIGNUP = 'SIGNUP',
      SIGNIN = 'SIGNIN',
      FORGOT_PASSWORD = 'FORGOT_PASSWORD',
      RESET_PASSWORD = 'RESET_PASSWORD',
      VERIFICATION_CODE = 'VERIFICATION_CODE'
      ;

const Signin: FC<{}> = () => {
    const [componentShow, setComponentShow] = useState(SIGNIN)
    const { isAuthenticated, 
            processPending, 
            user, 
            setPendingEmailOrPhone, 
            pendingEmailOrPhone,
            resetEmailOrPhone,
            verifyCurrentUserAttributeSubmit
           } = useAuthenticationContext();
    const navigate = useNavigate(); 
    const { awsRum } = useAWSRumContext();
    
    const { userLevelData, userData } = useSelector((state: MainAppReducer) => state.mainAppReducer)
    const auth = useSelector((state: AuthReducer) => state.authReducer)


    const navPlayGuest = () => {
      // dispatch(appActions.fetchClientDataRequest())
      navigate(`/welcome`)
    }

    const setComponentForgotPassword = () => {
      setComponentShow(FORGOT_PASSWORD)
    }

    const setComponentResetPassword = () => {
      setComponentShow(RESET_PASSWORD)
    }

    const setComponentSignIn = () => {
      setComponentShow(SIGNIN)
    }

    useEffect(()=>{
      if(isPhoneNumber(pendingEmailOrPhone)){
        setComponentShow(VERIFICATION_CODE)
      }
    }, [pendingEmailOrPhone])
  

    useEffect(()=>{
      if(isAuthenticated && auth.jwtToken) {
        setPendingEmailOrPhone(undefined);
        // Ensure that the userData has BECOME the user data of the user that just signed in!!
        // Otherwise it will show the state according to user data values of the last user before the state switches over.
        if (userData && userData.user_id == user?.attributes?.sub) { // ensure startup sagas has grabbed user data (or created inital state row)
          if (userData.subscription_status == "") { // Blank is before any trial has begun (new user state)
            navigate('/welcome')
          } else { 
            navigate('/roadmap')
          }
        }
      }
    },[isAuthenticated, navigate, setPendingEmailOrPhone, auth.jwtToken, userLevelData, userData])
  

    return (
      <Box className="page-div-styles">
        <Box className="filter-div-styles">
          <Box className="sign-in-div-styles">
            <Logo/>
            <Box className="desc-container welcome-container">
            {
              processPending &&
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CustomCircularProgress />
              </Box>
            }
              <h3 className="title-welcome" style={{marginTop: '50px', }}>Welcome to MuseFlow!</h3>

              { (componentShow === SIGNIN) && 
                <SignIn 
                forgotPasswordNavFunction={setComponentForgotPassword}
                goBackFn={setComponentSignIn}
                /> 
              }

              { (componentShow === FORGOT_PASSWORD && 
                <ForgotPassword 
                  goBackFn={setComponentSignIn} 
                  goToReset={setComponentResetPassword}
                />)
              }
            
              { (componentShow === RESET_PASSWORD && 
                <ResetPassword 
                  goToSignIn={setComponentSignIn} 
                  goBackFn={setComponentForgotPassword}
                />)
              }


              {
                (componentShow === VERIFICATION_CODE &&
                <VerificationCode 
                  backFn={()=>setComponentShow(SIGNIN)}
                  additionalCompletionFunction={async confirmationCode => {
                    if(pendingEmailOrPhone) {
                      await confirmSignUp(pendingEmailOrPhone, confirmationCode)
                    } else if(resetEmailOrPhone) {
                      await confirmSignUp(resetEmailOrPhone, confirmationCode)
                    }
                    setComponentShow(SIGNIN)
                  }
                  }
                />)
              }
            </Box>
          </Box>
        </Box>
      </Box>      
  )
}

const backgroundStyles1 = {
  backgroundImage: `url(${pianoBackground})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
}

const backgroundStyles2 = {
  width: '100%',
  height: '100%',
  backdropFilter: 'brightness(15%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

export default Signin;
