import * as fullNameParser from 'parse-full-name';
import { put, takeEvery, select } from 'redux-saga/effects'
import * as appActions from '../Actions/app'
import { actionTypes,AppState, TutorialData, UserLevelData, LevelData, UnitData, AppActions, TiersByLevels, SetUnitSelectPayload, SetAudioOn, AugmentedCognitoUser } from 'Types';
import { AxiosError, AxiosResponse } from 'axios';
import { getHighestCompletedLevel } from 'Utils/UserProgress'
import StartingULP from 'Fixtures/UserLevelProgress/StartingULP';
import { UpdateCurrentULP } from 'Types';
// import AxiosRetrier from 'Utils/AxiosRetrier';
import { UserTypes } from 'Utils/Constants';
import {getRum} from 'Utils/AwsRum';
import axios from 'axios';

const awsRum = getRum()
// const axios = new AxiosRetrier()
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`
interface unitsRequest {
  data: {
    data: UnitData[]
  }
}
interface levelsRequest {
  data: {
    data: LevelData[]
  }
}
interface userLevelProgressResponse {
  data: UserLevelData[]
}

interface tutorialsRequest {
  data: {
    data: TutorialData[]
  }
}

interface tiersByLevelsRequest {
  // data: TiersByLevels[]    <- Should be this, but server passes level_number as string
  data: {
    level_number: string;
    tiers: number[];
  }[]
}

interface userDataRequest {
  data: any
}


function* onStartup() {
  // yield console.log('Startup!!!!');
  yield put(
    appActions.setUnitSelect({unitSelect:1, authToken: undefined}) // this shouldn't be necessary - why is default state not set on load?
  )  
}

function* updateLevelsData(authToken: string) {
  const data:AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  // Now that we have the highest level the user has completed, we will query all levels for corresponding unit
  // And lower. (Onces we have multiple units, new users won't have their roadmap populated with these levels yet)
  // Now using the highest unit, grab all the levels up to and including that unit to populate the roadmap!
  let levels: LevelData[] = []
  let LevelsResponse:levelsRequest
  LevelsResponse = yield axios.get<any[]>(baseUrl+`levels?unitNumber=${data.unitSelect}&ascending=true`, {headers: {'Authorization': `Bearer ${authToken}`}})
  levels = levels.concat(LevelsResponse.data.data)
  
  yield put(
    appActions.fetchLevelDataSuccess({
      levelData: levels
    })
  )

  let TierCountByLevelResponse:tiersByLevelsRequest
  TierCountByLevelResponse = yield axios.get<any[]>(baseUrl+`phrases/tiers`, {headers: {'Authorization': `Bearer ${authToken}`}})
  // Passed from server with level_number as sring; so casting to into to match TiersByLevels type and easier use
  let TiersByLevels: TiersByLevels[] = TierCountByLevelResponse.data.map(v => ({...v, level_number: parseInt(v.level_number)}));
  yield put(
    appActions.updateTiersByLevels(
      TiersByLevels
    )
  )
}

function* onUnitSelect(action: any) {
  const authToken: string | undefined = action.payload.authToken
  if(authToken) {
    yield updateLevelsData(authToken)
  }
}

function* onAuthStartup(action: any) {
  const authToken: string = action.payload.authToken

  const data:AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  const { jwtToken } = yield select(({authReducer}) => authReducer)
  const user = action.payload.user
  // First check UserLevelProgress, pull all rows for user
  let ulp_url = baseUrl+`user-level-progress`
  let userLevelProgressResponse:userLevelProgressResponse
  userLevelProgressResponse = yield axios.get<any[]>(ulp_url, {headers: {'Authorization': `Bearer ${jwtToken}`}})
  let userLevelProgress: UserLevelData[] = userLevelProgressResponse.data
  // If user does not have a userLeverProgress, create a row for user
  if (Object.keys(userLevelProgress).length == 0) {
    console.log("posting starting ulp")
    const startingULP = (StartingULP() as UserLevelData)
    try {
      userLevelProgressResponse = yield axios.post<UserLevelData>(
        ulp_url, 
        startingULP,
        {headers: {'Authorization': `Bearer ${authToken}`}},
      )
      userLevelProgress = [(userLevelProgressResponse.data as unknown as UserLevelData)]
    } catch(err ) {
      awsRum?.recordError(err)
      // sometimes this can happen due to a race condition, so this is just a bit of extra error handling
      if( err instanceof AxiosError && err?.response?.data?.message === "User level progress already exists for user/level") {
        userLevelProgressResponse = yield axios.get<UserLevelData>(
          ulp_url, 
          {headers: {'Authorization': `Bearer ${authToken}`}},
        )
        console.log(userLevelProgressResponse)
        userLevelProgress = userLevelProgressResponse.data
        yield put(
          appActions.fetchUserLevelsSuccess({
            userLevelData: userLevelProgress
          })
        )
      }
    }
  } else {
    userLevelProgress = userLevelProgressResponse.data
  }
  //Now set user Level data
  yield put(
    appActions.fetchUserLevelsSuccess({
      userLevelData: userLevelProgress
    })
  )
  
  // Then calculate the lastestLevelComplete for the user.
  const highestCompleteData = getHighestCompletedLevel(userLevelProgress)
  let lastLevelComplete = highestCompleteData.lastLevelComplete
  
  // Now set highestLevelCompleted
  yield put(
    appActions.setHighestLevelCompleted({
      highestLevelCompleted: lastLevelComplete
    })
  )

  // Now that we have the highest level the user has completed, we will query all levels for corresponding unit
  // And lower. (Onces we have multiple units, new users won't have their roadmap populated with these levels yet)
  let UnitsResponse:unitsRequest
  // UnitsResponse = yield axios.get<any[]>(baseUrl+`units?levelNumber=${lastLevelComplete || 1}`, {headers: {'Authorization': `Bearer ${authToken}`}})
  
  // Now using the highest unit, grab all the levels up to and including that unit to populate the roadmap!
  let levels: LevelData[] = []
  let LevelsResponse:levelsRequest
  LevelsResponse = yield axios.get<any[]>(baseUrl+`levels?unitNumber=${data.unitSelect}&ascending=true`, {headers: {'Authorization': `Bearer ${jwtToken}`}})
  levels = levels.concat(LevelsResponse.data.data)
  
  yield put(
    appActions.fetchLevelDataSuccess({
      levelData: levels
    })
  )

  let TierCountByLevelResponse:tiersByLevelsRequest
  TierCountByLevelResponse = yield axios.get<any[]>(baseUrl+`phrases/tiers`, {headers: {'Authorization': `Bearer ${jwtToken}`}})
  // Passed from server with level_number as sring; so casting to into to match TiersByLevels type and easier use
  let TiersByLevels: TiersByLevels[] = TierCountByLevelResponse.data.map(v => ({...v, level_number: parseInt(v.level_number)}));
  yield put(
    appActions.updateTiersByLevels(
      TiersByLevels
    )
  )

  // Get user Data:
  
  let user_data_url = baseUrl+`user-data`
  let userDataResponse:userDataRequest
  try {
    userDataResponse = yield axios.get<any[]>(user_data_url, {headers: {'Authorization': `Bearer ${jwtToken}`}})
  } catch(err: any) {
    console.log("caught error makling get call to User Data.", )
    console.error(err)
    userDataResponse = yield axios.post<any[]>(
      user_data_url,
      {
        subscription_status: "",  // Could be a new state (not yet active) or null. But something to differentiate from the stripe "upaid" "canceled" states
        user_type: UserTypes.USER.toString(),
        name: user.attributes?.name, 
        email: user.attributes.email,
        phone_number: user.attributes?.phone_number
      },
      // {headers: {'Authorization': `Bearer ${jwtToken}`, 'id_token': `${idToken}`}}
      {headers: {'Authorization': `Bearer ${jwtToken}`}}

    )
  }
  yield put(
    appActions.setUserData(
      userDataResponse.data
    )
  )
  
  yield updateLevelsData(authToken)
}

function* onTutorialDataRequest(action: any) {
  const authToken: string = action.payload.authToken

  const data:AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  yield put(
    appActions.fetchTutorialDataProcessing(true)
  )  
  const levelNumber = data.levelData[data.levelSelect].level_number
  let tutorialsResponse: tutorialsRequest
  tutorialsResponse = yield axios.get<any[]>(baseUrl+`tutorials?levelNumber=${levelNumber}`, {headers: {'Authorization': `Bearer ${authToken}`}})

  let tutorialData = tutorialsResponse.data.data
  yield put(
    appActions.fetchTutorialDataSuccess({
      tutorialData: tutorialData
    })
  )
  yield put(
    appActions.fetchTutorialDataProcessing(false)
  ) 
  // let path = "/tutorial"
  // window.location.href = path // <- This is not idea solution, the push(path) should work, but doesn't
  // yield put(push(path))
}


function* onCurrentULPUpdate(action: UpdateCurrentULP) {
  const authToken: string = action.payload.authToken
  const data: AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  const level = data.levelData[data.levelSelect].level_number // This will determine which tutorial we grab!
  const toUpdateULP = action.payload.currentUserLevelProgress;
  let updatedULP: AxiosResponse<UserLevelData>;
  try {
    updatedULP = yield axios.patch<any>(
        baseUrl+`user-level-progress?levelNumber=${level}`,
        {
          level: {
            level_number: level
          },
          ...toUpdateULP,
          play_session_id: data.playSessionId
        },
        {
          headers: {'Authorization': `Bearer ${authToken}`}
        }
      )
    yield put(
      appActions.updateCurrentULPSuccess(updatedULP.data)
    )    
  } catch(err) {
    if( err instanceof AxiosError) {
      if(err?.response?.data?.message === "User level progress not found.") {
        updatedULP = yield axios.post<any>(
          baseUrl+`user-level-progress?levelNumber=${level}`,
          {
            ...StartingULP(),
            level: {
              level_number: level
            },
            play_session_id: data.playSessionId
          },
          {
            headers: {'Authorization': `Bearer ${authToken}`}
          }
        )
        yield put(
          appActions.updateCurrentULPSuccess(updatedULP.data)
        )
      }
    } else {
      awsRum?.recordError(err);
      console.error(err)
    }
  }
}

function* onAudioUpdate(action: SetAudioOn): any {
  try {
    const audioOn = action.payload.audioOn
    yield axios.put<any>(
      baseUrl+`user-data/set-user-audio?audio_on=${audioOn}`,
      {},
      {
        headers: {'Authorization': `Bearer ${action.payload.authToken}`}
      }
    )
    yield put(
      appActions.updateUserData({audio_on: audioOn})
    )
  } catch(err) {
    awsRum?.recordError(err);
    console.error(err)
  }
}

function* onsetLastShownSurvey(action: any): any {
  try {
    const lastShownSurvey = action.payload.last_shown_survey.toISOString()
    yield axios.put<any>(
      baseUrl+`user-data/set-last-shown-survey?last_shown_survey=${lastShownSurvey}`,
      {},
      {
        headers: {'Authorization': `Bearer ${action.payload.authToken}`}
      }
    )
    yield put(
      appActions.updateUserData({last_shown_survey: lastShownSurvey})
    )
  } catch(err) {
    awsRum?.recordError(err);
    console.error(err)
  }
}

function* onUpdateMailchimpInfo(action: any): any {
  try {
    const fullName = fullNameParser.parseFullName(action.payload.signUpData.name)
    yield axios.post<any>(
      baseUrl+`mailchimp/add-to-list`,
      {
        first_name: fullName.first,
        last_name: fullName.last,
        email: action.payload.signUpData.email
      },
      {
        headers: {'Authorization': `Bearer ${action.payload.authToken}`}
      }
    )
  } catch(err) {
    awsRum?.recordError(err);
    console.error(err)
  }
}

function* onGetSubscriptionStatus(action: any): any {
  try {
    const userDataResponse = yield axios.get<any[]>(
      baseUrl+`user-data`, 
      {
        headers: {'Authorization': `Bearer ${action.payload.authToken}`}
      }
    )
    yield put(
      appActions.setSubscriptionStatus(userDataResponse.data.subscription_status)
    )
  } catch(err) {
    awsRum?.recordError(err);
    console.error(err)
  }
  
}

export default [
  takeEvery(actionTypes.UPDATE_CURRENT_ULP, onCurrentULPUpdate),
  takeEvery(actionTypes.FETCH_TUTORIAL_DATA, onTutorialDataRequest),
  takeEvery(actionTypes.AUTHENTICATED_STARTUP, onAuthStartup),
  takeEvery(actionTypes.SET_UNIT_SELECT, onUnitSelect),
  takeEvery(actionTypes.SET_AUDIO_ON, onAudioUpdate),
  takeEvery(actionTypes.UPDATE_MAILCHIMP_INFO, onUpdateMailchimpInfo),
  takeEvery(actionTypes.SET_LAST_SHOWN_SURVEY, onsetLastShownSurvey),
  takeEvery(actionTypes.GET_SUBSCRIPTION_STATUS, onGetSubscriptionStatus),
  onStartup()
]
