import React from 'react';
import { Formik, Form } from "formik";
import { Box, Link, Typography } from '@mui/material';
import { ResetCodeData } from 'Types';
import { useAuthenticationContext } from 'Contexts/Authentication';
import ResetCodeForm, {resetCodeFormSchema} from 'Components/Forms/ResetCodeForm';
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import { isPhoneNumber } from 'Components/Forms/SignUpForm';
import GoBackButton from 'Components/GoBackButton';
import { MUSEFLOW_OFF_WHITE_1 } from 'Components/Colors';
import { resendSignUp } from 'Utils/Amplify';

type VerificationCodeProps = {
  additionalCompletionFunction?: (code: string) => void;
  backFn?:()=>void
}

const VerificationCode: React.FC<VerificationCodeProps> = (props) => {

  const { additionalCompletionFunction, backFn } = props;

  const { 
    forgotPassword,
    setConfirmationCode,
    setPendingEmailOrPhone,
    setPending,
    resetEmailOrPhone,
    pendingEmailOrPhone
   } = useAuthenticationContext();

  const [ codeAuthErrors, setCodeAuthErrors ] = React.useState({
    code: "",
  })

  const resetCodeAuthErrors = () => {
    setCodeAuthErrors({
      code: "",
    })
  }

  return(
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      {
        backFn && 
        <GoBackButton
        sx={{
          position: 'absolute',
          top: 30,
          left: 30,
          color: MUSEFLOW_OFF_WHITE_1
        }}
        onClick={()=>backFn()}
      >
        Back
      </GoBackButton>
      }
      {
        resetEmailOrPhone && isPhoneNumber(resetEmailOrPhone) && 
        <>
        <Typography 
          sx={{
            marginBottom: "20px"
          }}
          color="rgb(255,255,255,1.0)"
        >
          Check your SMS messages for a reset code.
        </Typography> 
        <Link 
          sx={{
            textAlign: 'center' as 'center',
            cursor: 'pointer'
          }}
          onClick={()=> {resendSignUp(resetEmailOrPhone || "")}}
        >
          {
          isPhoneNumber(resetEmailOrPhone) ?
            "Didn't get a Code? Click here to resend." :
            "Didn't get a link? Click here to resend."
          }
        </Link>
        </>
      }
      {
        pendingEmailOrPhone && isPhoneNumber(pendingEmailOrPhone) && 
        <>
        <Typography 
          sx={{
            marginBottom: "20px"
          }}
          color="rgb(255,255,255,1.0)"
        >
          Your registration is pending. Check your SMS messages for a verification code.
        </Typography> 
        <Link 
          sx={{
            textAlign: 'center' as 'center',
            cursor: 'pointer'
          }}
          onClick={()=> {resendSignUp(pendingEmailOrPhone || "")}}
        >
          {
          isPhoneNumber(pendingEmailOrPhone) ?
            "Didn't get a Code? Click here to resend." :
            "Didn't get a link? Click here to resend."
          }
        </Link>
        </>
      }
       <Box 
        sx={{
          maxWidth: '400px',
          margin: '20px'
        }}>
          <Typography
            sx={{
              fontSize: '20px',
              color: 'rgb(255,255,255,1.0)'
            }}
          > 
            Enter Verification Code
          </Typography>
        </Box>
        <Formik
        initialValues={{
          "code": "",
          "unknown": ""
        }}
        validate={(values: ResetCodeData) => {
          return ValidateForm(resetCodeFormSchema,values)}
        }
        onSubmit={async (values) => {
          setConfirmationCode(values.code)
          if(additionalCompletionFunction) {
            try {
              await additionalCompletionFunction(values.code);
            } catch(err: any) {
              let errs = handleAuthErrors(err)
              setCodeAuthErrors(errs)
            }
          }

        }}
        validateOnChange={false}
      >
        {formikProps => (
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              resetCodeAuthErrors();
              formikProps.handleSubmit(e);
            }}
          >
            <Box sx={{width: '350px', display: 'flex', flexDirection: 'column'}}>
              <ResetCodeForm 
                {...formikProps}
                errors={{...codeAuthErrors,...formikProps.errors}}
              />
            </Box>
          </Form>
        )}
        </Formik>
      </Box>
  )
}

export default VerificationCode;