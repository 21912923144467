
import { 
  authActionTypes, 
  SetTokenPayload,
  SetIdTokenPayload
 } from 'Types/AuthTypes'


 export const setToken = (payload: SetTokenPayload) => ({
  type: authActionTypes.SET_TOKEN,
  payload
})

export const setIdToken = (payload: SetIdTokenPayload) => ({
  type: authActionTypes.SET_ID_TOKEN,
  payload
})
