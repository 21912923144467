import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import PianoBackground from '../../assets/images/pianoBackground.png';
import Logo from 'Components/Logo';
import { CustomCircularProgress } from 'Components/StyledComponents';
import CheckIcon from '@mui/icons-material/Check';
import { handleAuthErrors } from 'Utils/Forms';


const Verification: React.FC<any> = () => {

  const [verified, setVerified] = React.useState(false);
  const [error, setError] = React.useState("");

  const navigate = useNavigate();
  const search = useLocation().search.slice(1);

  useEffect(()=>{
    const query = new URLSearchParams(search);
    const params: {[key: string]: string} = {};
    for (let [key, value] of query.entries()) {
      params[key] = value;
    }
    console.log(params)
    Auth.confirmSignUp(params['user_name'], params['confirmation_code'])
      .then(result => {
        console.log("success:")
        console.log(result)
        setVerified(true)
        setTimeout(()=>{
          navigate('/signin')
        },800)
      })
      .catch(error => {
        const err = handleAuthErrors(error)
        console.log(err)
        if(err?.code) setError(err.code)
        if(err?.unknown) setError(err.unknown)
      })
  },[])

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
    <img 
      style={{
        height: '100vh', 
        opacity: 0.3,
        position: 'absolute'
      }} 
      src={PianoBackground} 
      alt="piano-background">
    </img>
      <Box className="page-div-styles">
        <Box className="filter-div-styles">
          <Box className="sign-in-div-styles">
            <Logo/>
            <Box className="desc-container welcome-container">
           
              <Typography  variant='h3' className="title-welcome" style={{marginTop: '50px', }}>
                {!verified && "Verification Pending"}
                { verified && "Success" }
              </Typography>
              { !verified && <CustomCircularProgress/>}
              { verified && 
                <CheckIcon  color='success' sx={{height:'80px', width: '80px'}}/>
              }
              { error && <Typography color="red">{error}</Typography>}
            </Box>
            
          </Box>
        </Box>
      </Box>      
    </Box>      
  )

}

export default Verification