import { useState } from 'react'
import { FC } from 'react';
import { Button, Box, TextField, Link } from '@mui/material';
import './paywall.css'
import IntroFlowBack from 'Components/IntroFlowBack';
import CloseIcon from '../../assets/images/CloseIcon.png';
import { planPricing, discountPercentages, numTrialDays} from '../../Billing/Constants'
import axios from 'axios';
import WelcomeButton from '../TrialWelcome/WelcomeButton'
import LogOutButton from 'Components/LogOutButton';
import { SURVEY_LINK } from 'Utils/Constants';
import { AuthReducer } from 'Types/AuthTypes';
import { useSelector } from 'react-redux';

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`

// Backend endpoint validates backend hard coded list

interface PaywallProps {
  setShowPaywall: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPayment: React.Dispatch<React.SetStateAction<boolean>>;
  setPlanTypeSelected: React.Dispatch<React.SetStateAction<string>>;
  exitAllowed: boolean;
  discountCodeValid: boolean | null;
  setDiscountCodeValid: React.Dispatch<React.SetStateAction<boolean | null>>;
  discountCode: string;
  setDiscountCode: React.Dispatch<React.SetStateAction<string>>;
  showDiscountCode: boolean;
  setShowDiscountCode:React.Dispatch<React.SetStateAction<boolean>>;
}

const Paywall: FC<PaywallProps> = ({ setShowPaywall, setShowPayment, setPlanTypeSelected, exitAllowed, discountCodeValid, setDiscountCodeValid, discountCode, setDiscountCode, showDiscountCode, setShowDiscountCode }) => {
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const authToken = auth.jwtToken

  const validateDiscountCode = async () => {
    try {
      const response = await axios.post<any>(
        `${baseUrl}stripe/validate-coupon-code`,
        { 
          code: discountCode,
        },
        {
          headers: {'Authorization': `Bearer ${authToken}`}
        }
      )
      // const data = response.data
      setDiscountCodeValid(true)
    } catch {
      // if (data.errors && data.errors.code) {
      // console.log("Error validating code!", data.error);
        setDiscountCodeValid(false)
      // } else {
      //   setDiscountCodeValid(true)
      // }
    }
  }

  let inputClass
  if (discountCodeValid) {
    inputClass = 'discount-input valid-discount'
  } else if (discountCodeValid == false) {
    inputClass = 'discount-input invalid-discount'
  } else {
    inputClass = 'discount-input discount-input-neutral'
  }
  
  const submitPlanSelect = (planSelect: string) => {
    setPlanTypeSelected(planSelect)
    setShowPaywall(false)
    setShowPayment(true)
  }

  return (
    <>
    <Box className="modal-outer"/>
    <Box className="modal-inner2">
      {exitAllowed ?
        <Box sx={{
          position: 'absolute', top: '15px', right: '15px',
          '&:hover': { cursor: 'pointer' }}}
          onClick={() => {
            setShowPaywall(false)
          }}>
          <img  src={CloseIcon}/>
        </Box>
      : // if not allowed to bypass paywall; can return to tutorials or roadmap
        // Actually; no back either on paywall
        // <IntroFlowBack/>
        <Box></Box>
      }
        <Box className="paywall-desc-container">
        <h3 className="paywall-desc-title">Your free trial has ended.</h3>
        <p className="paywall-desc-text"> We hope you enjoyed your {numTrialDays}-day free trial! Please select a payment plan to continue playing. We’d also love to hear <Link href={SURVEY_LINK} target="_blank" className="link">any feedback you have</Link>. </p>
        </Box>

        <Box className="plan-container">
          <Box className="plan">
            <Box className="plan-card">
              {discountCodeValid &&
                <Box className="discount-percent">
                  <p className="discount-percent-text">
                    {`${discountPercentages.monthly.withCode}% off`}
                  </p>
                </Box>
              }
              <Box className="plan-title">
              <p className="plan-text-desc">Monthly Plan</p>
              <p className="plan-text-price">{`$${discountCodeValid ? planPricing.monthly.withCode : planPricing.monthly.regular}`}</p>
              <p className="plan-text-time">per month</p>
              </Box>
              <WelcomeButton onClick={() => submitPlanSelect('monthly')}>
                SELECT
              </WelcomeButton>
            </Box>
          </Box>
          <Box className="plan">
            <Box className="plan-card">
              <Box className="discount-percent">
                <p className="discount-percent-text">
                  {`${discountCodeValid ? discountPercentages.annually.withCode : discountPercentages.annually.regular}% off`}
                </p>
              </Box>
              <Box className="plan-title">
              <p className="plan-text-desc">Annual Plan</p>
              <p className="plan-text-price">{`$${discountCodeValid ? planPricing.annually.withCode : planPricing.annually.regular}`}</p>
              <p className="plan-text-time">per year</p>
              </Box>
              <WelcomeButton onClick={() => submitPlanSelect('annual')}>
                SELECT
              </WelcomeButton>
            </Box>
          </Box>
        </Box>
        
        {(!showDiscountCode) &&
        <Box className="submit-container">
          {/* <Button onClick={() => navigate('/roadmap')} className="submit-button" variant="contained">Start 14 Day Free Trial</Button> */}
          <Button onClick={() => setShowDiscountCode(true)} className="discount-code">Got a discount code?</Button>
        </Box>
        }
        {showDiscountCode &&
          <Box className="discount-container">
            <Box className="code-field-container">
              <TextField
                className={inputClass}
                label="Enter Discount Code"
                value={discountCode}
                onChange={e => {
                  setDiscountCode(e.target.value)
                }}
              />
              {discountCodeValid ? 
                <Box className="code-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                    <path d="M6 1.37158C3.25 1.37158 1 3.62158 1 6.37158C1 9.12158 3.25 11.3716 6 11.3716C8.75 11.3716 11 9.12158 11 6.37158C11 3.62158 8.75 1.37158 6 1.37158ZM5 8.87158L2.5 6.37158L3.205 5.66658L5 7.45658L8.795 3.66158L9.5 4.37158L5 8.87158Z" fill="#2E5B3E"/>
                  </svg>
                  CODE APPLIED
                </Box>
                :
                ((discountCodeValid == false) &&
                  <Box className="code-container invalid">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                      <path d="M6.5 6.87158H5.5V3.87158H6.5M6.5 8.87158H5.5V7.87158H6.5M6 1.37158C5.34339 1.37158 4.69321 1.50091 4.08658 1.75218C3.47995 2.00346 2.92876 2.37176 2.46447 2.83605C1.52678 3.77373 1 5.0455 1 6.37158C1 7.69766 1.52678 8.96943 2.46447 9.90712C2.92876 10.3714 3.47995 10.7397 4.08658 10.991C4.69321 11.2423 5.34339 11.3716 6 11.3716C7.32608 11.3716 8.59785 10.8448 9.53553 9.90712C10.4732 8.96943 11 7.69766 11 6.37158C11 5.71497 10.8707 5.06479 10.6194 4.45816C10.3681 3.85154 9.99983 3.30034 9.53553 2.83605C9.07124 2.37176 8.52005 2.00346 7.91342 1.75218C7.30679 1.50091 6.65661 1.37158 6 1.37158Z" fill="#B54520"/>
                    </svg>
                    INVALID CODE
                  </Box>
                )
              }
            </Box>
            <Button onClick={() => validateDiscountCode()} className="discount-button" >Submit</Button>
          </Box>
        }
      <Box className="logout-container">
        <LogOutButton/>
      </Box>
      </Box>
    </>
  )
}

export default Paywall;

