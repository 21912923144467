import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import React from 'react'
import MenuLogo from '../../assets/images/MenuLogo.png';
import MapIcon from '../../assets/images/MapIcon.png';
import HelpIcon from '../../assets/images/HelpIcon.png';
import SettingsIcon from '../../assets/images/SettingsIcon.png';
import PersonIcon from '@mui/icons-material/Person';
import MenuLogoSelected from '../../assets/images/MenuLogoSelected.png';
import MapIconSelected from '../../assets/images/MapIconSelected.png';
import HelpIconSelected from '../../assets/images/HelpIconSelected.png';
import SettingsIconSelected from '../../assets/images/SettingsIconSelected.png';
import { useNavigate } from "react-router-dom";
import LogOutButton from '../../Components/LogOutButton'
import Fullscreen from '../../Components/Fullscreen';

interface MenuProps {
  selected: string;
}

export const Menu = (props: MenuProps) => {
  const navigate = useNavigate(); 
  const navMenu = (path: string) => {
    if (path === 'faq') {
      window.open('https://museflow.ai/faq', '_blank');
    } else {
      navigate(`/${path}`);
    }
  }
  let selected = props.selected

  return (
    <Box sx={menuContainerStyles}>
      <Box sx={{
        backgroundColor: '#1D2F44',
        height: '100%',
        width: '100px',
        position: 'fixed', 
        top: '0px',
        display: 'flex', flexDirection: 'column', alignItems: 'center'
      }}>

      <Box sx={{marginTop: '30px', marginLeft: '4px', marginBottom: '10px', maxWidth: '100%'}}>
        <MenuLogoImg/>
      </Box>

      <Box onClick={() => navMenu('roadmap')} sx={hoverBoxStyles}>
        { selected=="Roadmap" ? 
        <img style={imgStyle} src={MapIconSelected}/>
        :
        <img style={imgStyle} src={MapIcon}/>
        }
        <Box sx={textStyles}>Home</Box>
      </Box>

      <Box onClick={() => navMenu('faq')} sx={hoverBoxStyles}>
        { selected=="FAQ" ? 
        <img style={imgStyle} src={HelpIconSelected}/>
        :
        <img style={imgStyle} src={HelpIcon}/>
        }
        <Box sx={textStyles}>Help</Box>
      </Box>

      <Box onClick={() => navMenu('settings')} sx={hoverBoxStyles}>
        <PersonIcon
          sx={{
            color: selected=="Settings" ? '#6F87F1' : '#ADB1B7',
            fontSize: '32px',
          }}
        />
        <Box sx={textStyles}>Account</Box>
      </Box>

      <Box sx={{...iconContainerStyles1, ...hoverBoxStyles}}>
          <LogOutButton/>
          <Box sx={{...textStyles, marginTop: '0px'}}>Log Out</Box>
      </Box>

      <Fullscreen show_label={true} />
      
      </Box>

    </Box>
  )
}

const menuContainerStyles = {
  width: '100px', 
  float: 'left',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
}

export const iconContainerStyles1 = {
  display: 'flex', 
  flexDirection: 'column',
  justifyContent: 'center'
}

export const textStyles = {
  marginTop: '10px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '15px',
  lineHeight: '18px',
  textAlign: 'center',
  letterSpacing: '0.01em',
  color: '#ADB1B7',
}

export const hoverBoxStyles = {
  marginTop: '44px',
  borderRadius: 4,
  opacity: 0.8,
  padding: '0px 0px 0px 0px',
  '&:hover': {
    background: 'rgba(202,124,98,.3)',
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 6px 6px rgb(222,144,118,.3)',
  },
}

const imgStyle = {
  width: '24px'
}

const MenuLogoImg = () => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
    <g clipPath="url(#clip0_2082_973)">
      <path d="M34.285 15.9397C33.2255 18.5376 30.5196 25.6453 25.9718 23.3905C25.5715 23.192 25.2296 22.9256 24.9373 22.6044C21.9082 19.2765 25.2484 9.71517 22.1086 9.71436C18.9688 9.71354 19.3212 19.2239 16.974 19.3219C14.6267 19.42 16.1288 12.7041 14.0888 11.7265C12.0488 10.749 11.4861 14.6972 8.82118 20.4425C6.15624 26.1879 2.03496 24.9059 -0.286377 20.4425" stroke="white" strokeWidth="1.70667" strokeLinecap="round"/>
    </g>
    <rect x="1" y="1" width="32" height="32" rx="16" stroke="white" strokeWidth="1.70667"/>
    <defs>
      <clipPath id="clip0_2082_973">
        <rect x="1" y="1" width="32" height="32" rx="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}