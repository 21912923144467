import { UserLevelData, LevelData, LevelStatus } from '../Types'
// The level selected by default should be the latest that they are 
// working on (latest datetime of level incomplete)
// or if complete, next latest incomplete level
const getHighestCompletedLevel = (userLevelData: UserLevelData[]) => {
  // Note lastLevelComplete is meaing "highest level complete"
  // We may want to add highestLevelComplete to replace lastLevelComplete
  // and lastLevelComplete can become "the level the user most recently completed" 
  // which we could use to scroll to on the roadmap
  let lastLevelComplete = 0
  let userLevelStatuses: {[k: number]: string}  = {}
  let userLevelTiersComplete: {[k: number]: number}  = {}
  userLevelData.forEach(ul => {
    if (ul.level) {
      userLevelStatuses[ul.level.level_number] = ul.status
      // Only show last level as highest level complete
      if (ul.status == LevelStatus.complete && ul.level.level_number > lastLevelComplete) {
        lastLevelComplete = ul.level.level_number
      }
      userLevelTiersComplete[ul.level.level_number] = ul.highest_tier_complete
    }
  })
  return {
    lastLevelComplete: lastLevelComplete, 
    userLevelStatuses: userLevelStatuses,
    userLevelTiersComplete: userLevelTiersComplete,
  }
}

const getUnitPctComplete = (highestCompletedLevel: number, levelData: LevelData[] ) => {
  return highestCompletedLevel/(levelData.length || 26)
}

export { getHighestCompletedLevel, getUnitPctComplete }
