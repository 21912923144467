import React from "react";
import immutable from 'immutable';
import * as auth from 'Utils/Amplify';
import { Auth, Hub } from 'aws-amplify'

import { UseStateError } from 'Utils/Errors';
import { SignUpData, AmplifyAuthError } from "Types";
import { useDispatch } from "react-redux";
import * as authActionTypes from 'Actions/auth'
import * as eventActionTypes from 'Actions/events';

export enum AuthStatus {
  UNAUTHENTICATED,
  PENDING,
  ERROR,
  SUCCESS
}

export enum UserActionTypeEnum {
  LOG_IN,
  LOG_OUT,
  SIGN_UP
}

export type AuthenticationStateType = {
  // For now this is any until we know the shape of what cognito returns 
  user?: any | undefined;
  confirmationCode: string | undefined;
  resetEmailOrPhone: string | undefined;
  authStatus: AuthStatus;
  error: Error | undefined;
  processPending: boolean;
  authPending: boolean;
  isAuthenticated: boolean;
  pendingEmailOrPhone: string | undefined;
  pendingEmailSuccessful: boolean;
};

export type AuthenticationFunctions = {
  signIn: (userName: string, password:string) => Promise<void>;
  signOut: () => Promise<void>;
  signUp: (signUpData: SignUpData)  => Promise<void>;
  forgotPassword: (password: string) => Promise<void>;
  resetError: () => void;
  setConfirmationCode: (code:string | undefined)=>void;
  setResetEmailOrPhone: (emailOrPhone: string | undefined)=>void;
  setPending: () => void;
  setPendingEmailOrPhone: (pendingEmailOrPhone: string | undefined) => void;
  submitNewPassword: (resetEmailOrPhone: string | undefined, code: string, password: string) => Promise<void>;
  resendSignUp: (username: string) => void;
  updateUserAttributes: (attributes: object) => Promise<string>;
  verifyCurrentUserAttributeSubmit: (attribute: string, code: string) => Promise<string>; 
  verifiedContact:() => Promise<{verified:{}, unverified:{}}>;
  verifyUserAttribute: (attribute: string) => Promise<void>;
  userAttributes: () => Promise<any>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<"SUCCESS">
  refreshUser: () => Promise<any>;
}

const initialState = { 
  resetEmailOrPhone: undefined,
  authStatus: AuthStatus.PENDING, 
  error: undefined, 
  user: undefined,
  confirmationCode: undefined,
  processPending: true,
  pendingEmailOrPhone: undefined,
  authPending: true,
  isAuthenticated: false,
  pendingEmailSuccessful: false
};

export const AuthenticationContext = React.createContext<(AuthenticationStateType & AuthenticationFunctions) | undefined>(undefined);


export const useAuthenticationContext: () => AuthenticationStateType & AuthenticationFunctions = () => {
  const state = React.useContext(AuthenticationContext);
  if (!state) {
    throw new UseStateError(useAuthenticationContext, AuthenticationProvider);
  }

  return {
    ...state
  };
};

export type AuthenticationProviderProps = {
  children?: React.ReactNode;
};

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children
}: AuthenticationProviderProps) => {

  const [state, setState] = React.useState<AuthenticationStateType>(initialState) 
  const dispatch = useDispatch();



  React.useEffect(() => {
    setPending()
    auth.getCurrentUser()
      .then((user) => {
        setAuthenticated(user)
        dispatch(authActionTypes.setToken({token: user?.signInUserSession?.accessToken?.jwtToken}))
        // console.log("setting ID token: " + user?.signInUserSession?.idToken?.jwtToken)
        dispatch(authActionTypes.setIdToken({idToken: user?.signInUserSession?.idToken?.jwtToken}))
      })
      .catch((error: Error) => {
        setUnauthenticated()
      })
  }, [])

  React.useEffect(() => {
    const unsubscribe = Hub.listen("auth", async ({ payload: { event, data }}) => {
      switch (event) {
        case "signIn":
          Auth.currentUserPoolUser()
          setAuthenticated(data);
          dispatch(authActionTypes.setToken({token: data?.signInUserSession?.accessToken?.jwtToken}))
          // console.log("setting ID token 2: " + data?.signInUserSession?.idToken?.jwtToken)
          dispatch(authActionTypes.setIdToken({idToken: data?.signInUserSession?.idToken?.jwtToken}))
          dispatch(eventActionTypes.signInEventAction())
          break;
        case "signOut":
          setUnauthenticated()
          break;
        case "signIn_failure":
          setUnauthenticated()
          break;
        case 'tokenRefresh':
          const userSession = await Auth.currentSession()
          console.log("refresh")
          dispatch(authActionTypes.setToken({token: (userSession as any)?.accessToken?.jwtToken}))
          // console.log("setting ID token 3: " + (userSession as any)?.idToken?.jwtToken)

          dispatch(authActionTypes.setIdToken({idToken: (userSession as any)?.idToken?.jwtToken}))
          break;
        default:
          console.log("logging event")
          console.log(event)
          console.log(data)
      }
    });
    return unsubscribe;
  }, []);

  
  const setAuthenticated = (user: any) => {
    setState(
      immutable.merge(
        state,
        {
          authStatus: AuthStatus.SUCCESS,
          user,
          isAuthenticated: !!user,
          processPending: false,
          authPending: false
        }
      )
    )
  }


  const setUnauthenticated = () => {
    setState(immutable.merge(
      state,
      {
        authStatus: AuthStatus.UNAUTHENTICATED,
        user: undefined,
        isAuthenticated: false,
        processPending: false,
        authPending: false,
        resetEmail:undefined,
        confirmationCode: undefined,
      }
    ))
  }
  const setPending = () => {
    setState(
      immutable.merge(
        state,
        {
          authStatus: AuthStatus.PENDING,
          user: undefined,
          isAuthenticated: false,
          processPending: true,
          authPending: true
        }
      )
    )
  }

  const setAuthError = (error: AmplifyAuthError) => {
    setState(immutable.merge(
      state, 
      {
        authStatus: AuthStatus.UNAUTHENTICATED,
        error,
        processPending: false,
        authPending: false
      }
    ))
  }

  const resetError = () => {
    setState(immutable.merge(state,{error: undefined}));
  }

  const setPendingEmailOrPhone = (pendingEmailOrPhone: string | undefined) => {
    setState(immutable.merge(state, {pendingEmailOrPhone}));
  }

  const setResetEmailOrPhone = (emailOrPhone: string | undefined) => {
    setState(immutable.merge(state, {resetEmailOrPhone: emailOrPhone}))
  }
  const setConfirmationCode = (code: string | undefined) => {
    setState(immutable.merge(state, {confirmationCode: code}))
  }

  const signIn = async (emailOrPhone: string, password: string): Promise<void> => {
    setState(immutable.merge(state, {processPending: true}));  
    try {
      const user = await auth.signIn(emailOrPhone, password)
      setAuthenticated(user)
      return;
    } catch(error) {
      setState(immutable.setIn(state, ['error'], error));
      setState(immutable.setIn(state, ['processPending'], false));      
      setAuthError(error as AmplifyAuthError)
      throw error;
    }
  }

  const signOut = async (): Promise<any> => {
    try{
      await auth.signOut()
      setUnauthenticated();
      return;
    } catch(error) {
      setState(immutable.setIn(state, ['error'], error));
      setState(immutable.setIn(state, ['processPending'], false));
    }
  }

  const signUp = async (signUpData: SignUpData): Promise<any> => {
    setPending();
    try{
      await auth.signUpUser(signUpData)
      return;
    } catch(error) {

      setState(immutable.setIn(state, ['error'], error));
      setState(immutable.setIn(state, ['processPending'], false));
      throw error
    }
  }


  const forgotPassword = async (emailOrPhone: string): Promise<void> => {
    setState(immutable.merge(
      immutable.setIn(state, ["processPending"],true)
    ));
    try {
      await auth.forgotPassword(emailOrPhone);         
      setState(immutable.setIn(state, ['processPending'], false));
    } catch(error) {
      setState(immutable.setIn(state, ['error'], error));
      setState(immutable.setIn(state, ['processPending'], false));
      throw error;
    }
  }

  const submitNewPassword = async (resetEmailOrPhone: string | undefined, code: string, password: string) => {
    setState(immutable.merge(state, {processPending: true}));  
    try{
      await auth.submitForgotPassword(resetEmailOrPhone || "", code, password);
      setState(immutable.merge(state, {processPending: false, resetEmailOrPhone: undefined, confirmationCode: undefined}));
    } catch(error) {
      setState(immutable.merge(state, {error, processPending: false}));
      throw error;
    } 
  }

  const refreshUser = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
    setState(immutable.merge(
      state,
      {
        user
      }
    ))
  }


  return (
    <AuthenticationContext.Provider 
      value={{
        ...state, 
        signIn,
        signOut,
        signUp,
        forgotPassword,
        resetError,
        setConfirmationCode,
        setResetEmailOrPhone,
        setPending,
        setPendingEmailOrPhone,
        submitNewPassword,
        resendSignUp: auth.resendSignUp,
        updateUserAttributes: auth.updateUserAttributes,
        verifyCurrentUserAttributeSubmit: auth.verifyCurrentUserAttributeSubmit,
        verifiedContact: auth.verifiedContact,
        verifyUserAttribute: auth.verifyUserAttribute,
        userAttributes: auth.userAttributes,
        changePassword: auth.changePassword,
        refreshUser
      }}
    >
        {children}
    </AuthenticationContext.Provider>
  );
};
