import React, { useCallback } from 'react';
import { Box } from "@mui/system"
import CloseIcon from '../../assets/images/CloseIcon.png';
import { useNavigate } from 'react-router-dom';
import RoadmapTierComplete from '../../assets/images/RoadmapTierComplete.png'
import {  useDispatch, useSelector } from 'react-redux'
import * as appActions from 'Actions/app';
import { MainAppReducer } from 'Types';
import { getLevelDataByLevelNumber } from 'Utils';
import { useAuthenticationContext } from "Contexts/Authentication";
import ShiningStars from '../../assets/images/ShiningStars.png';
import Star from '../../assets/images/Star.png';
import ColoredLogo from '../../assets/images/ColoredLogo.png';
import { AuthReducer } from 'Types/AuthTypes';
const UnitComplete = (props: any) => {
  const { user } = useAuthenticationContext();
  const dispatch = useDispatch();
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const { showUnitComplete, setShowUnitComplete } = props
  const navigate = useNavigate(); 
  const levelComplete = data?.currentUserLevelData?.current_tier && data?.currentUserLevelData?.current_tier >= data.currentLevelTiers

  const updateTier = useCallback((tier: number)=>{
    dispatch(appActions.updateCurrentULP({
      authToken: auth.jwtToken,
      currentUserLevelProgress: {
        current_tier: tier
      },
      user,
    }))
  },[dispatch, auth.jwtToken])
  
  if (!showUnitComplete) { return <></>}
  return (
    <Box sx={{
      position: 'fixed',
      width: '60%',
      height: '60%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -45%)',
      zIndex: 3,
      background: 'linear-gradient(229.67deg, #1D2F44 -6.17%, #071423 89.04%)',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', justifyContent: 'space-between'
    }}>
      <Box sx={{
        position: 'absolute', top: '15px', right: '15px',
        '&:hover': {
          cursor: 'pointer'
        }}}
        onClick={() => {
          if(!levelComplete && data?.currentUserLevelData?.current_tier) {
            updateTier(data?.currentUserLevelData?.current_tier + 1)
          } else if(levelComplete) {
            dispatch(appActions.updatePhrasesTilNextTier(4))
          }
          setShowUnitComplete(false)
        }}>
        <img  src={CloseIcon}/>
      </Box>

      <Box sx={{position: 'absolute', top: '35px', right: '80px', height: '120px'}}>
        <img style={{maxHeight: '100%'}} src={ShiningStars}/>
      </Box>
      <Box sx={{position: 'absolute', bottom: '50px', left: '50px', height: '120px'}}>
        <img style={{maxHeight: '100%'}} src={Star}/>
      </Box>

      <Box sx={{height: '80px', marginTop: '50px'}}>
        <img style={{maxHeight: '100%'}} src={ColoredLogo}/>
      </Box>

      <Box>
      <Box sx={{
        marginTop: '10px',
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '60px',
        lineHeight: '80px',
        color: 'white',
        letterSpacing: '0.01em',
      }}>
        Congratulations!
      </Box>
      <Box sx={{
        marginTop: '10px',
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '28px',
        lineHeight: '32px',
        color: 'white',
        letterSpacing: '0.01em',
      }}>
        You've completed Unit 1
      </Box>
      </Box>
      <Box sx={{
        marginTop: '30px',
        marginBottom: '100px',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: '0.01em',
        color: '#CA7C62',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      onClick={() => {
        if(!levelComplete && data?.currentUserLevelData?.current_tier) {
          updateTier(data?.currentUserLevelData?.current_tier + 1)
        } else if(levelComplete) {
          dispatch(appActions.updatePhrasesTilNextTier(4))
        }
        setShowUnitComplete(false)
        navigate('/roadmap')
      }}
      >
        Back to Level Roadmap
      </Box>

      <Box sx={{position: 'absolute', left: '0px', top:'0px', width: '100%', height: '100%', zIndex: -100}}>
        <img src={RoadmapTierComplete} style={{width: '100%', height: '100%'}}/>
      </Box>
    </Box>
  )

}
export default UnitComplete
