
import React from 'react';
import { Box, Link } from '@mui/material';
import { Formik } from "formik";
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import SignUpForm, {signUpFormSchema} from 'Components/Forms/SignUpForm';
import { useAuthenticationContext } from 'Contexts/Authentication';
import { SignUpData } from 'Types';
import VerificationPending from './VerificationPending';
import { MUSEFLOW_OFF_WHITE_1, MUSEFLOW_OFF_WHITE_3 } from 'Components/Colors';

type SignUpProps = {
  goBackFn: () => void
}

const SignUp: React.FC<SignUpProps> = (props) => {

  const { goBackFn } = props;

  const { signUp, resendSignUp,  setPendingEmailOrPhone, pendingEmailOrPhone } = useAuthenticationContext();

  const [ authErrors, setAuthErrors ] = React.useState({
    name:"",
    email: "",
    password: "",
    repeatPassword: "",
    unknown: ""
  })
  const resetAuthErrors = () => {
    setAuthErrors({
      name:"",
      email: "",
      password: "",
      repeatPassword: "",
      unknown: ""
    })
  }

  return (
    <Box 
      sx={{
        display:'flex', 
        flexDirection:'column', 
        alignItems:'center', 
        marginTop: '0px', 
        marginBottom: '20px'
      }}
    >
      {
        pendingEmailOrPhone && 
        <VerificationPending
        resendFn={() => resendSignUp(pendingEmailOrPhone || "")}
        goBackFn={() => {
          setPendingEmailOrPhone(undefined)
          goBackFn()
        }}
        />
      }
      {
        !pendingEmailOrPhone && 
      <Formik
        initialValues={{
          "name": "",
          "emailOrPhone": "",
          "password": "",
          "repeatPassword": "",
          "unknown": ""
        }}
        validate={async (values: SignUpData) => {
          return ValidateForm(signUpFormSchema,values)
        }}
        onSubmit={async (values) => {
          return signUp(values)
          .then(result => {
            setPendingEmailOrPhone(values.emailOrPhone)
            goBackFn()
          })
          .catch(err => {
            setAuthErrors(handleAuthErrors(err))
          });
        }}
        validateOnChange={false}
      >
        {formikProps => (
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              resetAuthErrors();
              formikProps.handleSubmit(e);
            }}
          >
            <Box sx={{width: '350px', display: 'flex', flexDirection: 'column'}}>
              <SignUpForm 
                {...formikProps}
                errors={{...authErrors,...formikProps.errors}}
              />
            </Box>
          </form>
        )}
      </Formik>
      }
      <Box
        sx={{
          margin: '0px 32px 0px 32px',
          marginTop: '20px',
        }}
      >
        <p style={{color: '#767C8C', lineHeight: '133%'}}>
        By signing up, you are agreeing to MuseFlow’s <Link href={"https://www.museflow.ai/terms"} target={"_blank"} sx={{color: '#6F87F1'}}> Terms of Service</Link>.
        </p>
      </Box>
      </Box>
  )
}

export default SignUp;